<template>
<div class="card">
        <s-form @submit="addItem" ref="form" class="">

            <div class="row">
                <div class="col-lg-12 ">
                    <validated-vue-select :options="levels" label="Level" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     :rules="{required:true}" placeholder="Select Level" v-model="model.name" @input="addData(model)"></validated-vue-select>
                </div>
                <div class="col-lg-12 ">
                    <validated-input label="Course" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     :rules="{required:true}" placeholder="Course" v-model="model.course"></validated-input>
                </div>
                <div class="col-lg-12 ">
                    <validated-input label="Institution" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     :rules="{required:true}" placeholder="Institution" v-model="model.institution"></validated-input>
                </div>

            </div>
         <div class="col-12 text-right">
                            <btn text="Save"  :loading="loading" loading-text="saving..." class="px-4"></btn>

             </div>
        </s-form>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'educationDetailAdd',
    data () {
        return {
            addStatus: true,
            nameArray: [],
            URL: urls.auth.EducationAddEdit,
            loading: false,
            model: {
                name: '',
                course: '',
                institution: '',
                user: this.$route.params.id
            },
            rules: {

            }
        };
    },

    mounted () {
        /*  this.filteringNames(); */
    },
    props: {
        levels: {
            type: Array
        },
        data: {
        }
    },
    methods: {

        checkDuplication (type) {
            console.log('In check duplication function');
            const existingItems = this.data.filter(obj => obj.name === type);
            console.log('existingItems.length', existingItems.length);
            if (existingItems.length >= 1) {
                this.addStatus = false;
                this.$notify('Education level already exist.', 'Warning', { type: 'warning' });
            } else {
                this.addStatus = true;
            }
        },

        async addItem () {
            this.checkDuplication(this.model.name);
            if (this.addStatus) {
                try {
                    this.loading = true;
                    const response = await axios.form(this.URL, this.model);
                    const result = response.data;
                    console.log('result', result);
                    if (result.success === true) {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        console.log('data', result.data);
                        this.loading = false;
                        this.$emit('success', result.data);
                    } else {
                        const errors = response.data.errors;

                        for (const key in errors) {
                            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                                const errorMessage = `${key}:  ${errors[key]}`;
                                this.$notify(errorMessage, 'Warning', { type: 'warning' });
                            }
                        }
                        this.loading = false;
                    }
                } catch (error) {
                    this.$notify(
                        'Something Went Wrong..!!',
                        error.response.status + ' ' + error.response.statusText,
                        { type: 'danger' }
                    );
                    this.loading = false;
                }
            }
        }

    }
};
</script>

<style scoped>

</style>
