<template>
<div class="main">
  <div class="card">
      <div class="">
        <h5 class="text-primary">Name</h5>
          <h6>{{data.title}}</h6>
      </div>
      <div class="mt-4">
        <h5 class="text-primary">Description</h5>
          <h6>{{data.description}}</h6>
      </div>

<!--    <div class="mt-4">
        <h4 class="text-primary">Recipients</h4>
          <h5></h5>
      </div>-->
  </div>
</div>
</template>

<script>
export default {
    name: 'viewNotification',
    props: {
        data: {
            type: Array
        }
    }
};
</script>

<style scoped>

</style>
