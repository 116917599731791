<template>
<div class="main">
  <div class="card">
      <div>
        <h5 class="text-primary">Name</h5>
          <h6>{{data.title}}</h6>
      </div>
      <div class="mt-3">
        <h5 class="text-primary">Description</h5>
          <h6>{{data.description}}</h6>
      </div>
  </div>
</div>
</template>

<script>
export default {
    name: 'notificationDetail',
    props: {
        data: {
            type: Array
        }
    }
};
</script>

<style scoped>

</style>
