<template>
    <div class="main">
        <s-form @submit="addItem" ref="form">
            <div class="row">
                <div class="col-6">
                    <validated-input class="field-required" label="First Name" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="data.first_name"
                                     :rules="{required:true}" placeholder="First name"></validated-input>
                </div>
                <div class="col-6">
                    <validated-input label="Last Name" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="data.last_name"
                                     placeholder="Last name"></validated-input>
                </div>

                <div class="col-6">
                    <validated-vue-select class="field-required" :options="genderOption" label="Gender" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                          v-model="data.gender" placeholder=" select gender" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-6">
                    <validated-date-picker class="field-required" label="Date Of Birth" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="data.dob"
                                           format="DD-MM-YYYY" :rules="{required:true}" :disabled-date="disableFutureDate"></validated-date-picker>
                </div>

                <div class="col-6">
                    <validated-input type="number" class="field-required" label="Phone Number" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     placeholder="phone" v-model="data.mobile_number" :rules="rules.phoneNumValidation"></validated-input>
                </div>
                <div class="col-6">
                    <validated-input type="mail" class="field-required" label="Email" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" placeholder="Email"
                                     v-model="data.email" :rules="rules.emailValidation"></validated-input>
                </div>

                <div class="col-6">
                    <validated-vue-select :options="designationOptions" class="field-required" label="Designation" label-classes="font-weight-600" input-classes="mt-2"
                                          placeholder="Designation" v-model="data.designation" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-12 text-right">
                            <btn text="Save" :loading="loading" loading-text="saving..." class="px-4"></btn>

                        </div>

            </div>
        </s-form>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'editUser',
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            URL: urls.auth.userAddEdit,
            loading: false,
            model: {
                first_name: '',
                last_name: '',
                gender: '',
                dob: '',
                mobile_number: '',
                email: '',
                designation: ''
            },
            designationOptions: [
                { value: 'HR', label: 'HR' },
                { value: 'Team Lead', label: 'Team Lead' },
                { value: 'Frontend Developer', label: 'Frontend Developer' },
                { value: 'Junior Frontend Developer', label: 'Junior Frontend Developer' },
                { value: 'Backend Developer', label: 'Backend Developer' },
                { value: 'Junior Backend Developer', label: 'Junior Backend Developer' },
                { value: 'Project Manager', label: 'Project Manager' },
                { value: 'Tester', label: 'Tester' }
            ],
            rules: {
                emailValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.isValidEmail(value);
                    }
                },
                phoneNumValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.isValidMobileNumber(value);
                    }
                }
            },
            genderOption: [
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' }
            ]
        };
    },
    methods: {
        isValidMobileNumber (phoneNumber) {
            const minDigits = 10;
            const maxDigits = 13;

            if (phoneNumber.length < minDigits || phoneNumber.length > maxDigits) {
                return 'Enter a valid number (between ' + minDigits + ' and ' + maxDigits + ' digits)';
            }

            if (!/^\d+$/.test(phoneNumber)) {
                return 'Enter a valid number (only digits)';
            }

            return true;
        },
        isValidEmail (email) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (emailRegex.test(email)) {
                return true;
            } else {
                return 'Please enter a valid email address';
            }
        },
        disableFutureDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.data);
                const result = response.data;
                if (result.success) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.$emit('success');
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }

};
</script>

<style scoped>

</style>
