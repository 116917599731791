<template>
    <div class="card">
        <s-form @submit="addItem" ref="form" class="">

                <div class="row">
                    <div class="col-6">
                    <validated-input  label="First Name" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="model.first_name" :rules="{required:true}"  placeholder="First name"></validated-input>
                </div>
                <div class="col-6 ">
                    <validated-input  label="Last Name" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="model.last_name"  placeholder="Last name"></validated-input>
                </div>
                </div>

            <div class="row">
                <div class="col-6 ">
                    <validated-vue-select :options="genderOption" class="field-required"  label="Gender" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="model.gender" :rules="{required:true}" placeholder=" select gender"></validated-vue-select>
                </div>
                <div class="col-6 ">
                    <validated-date-picker  label="Date Of Birth" class="field-required" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="date of birth" v-model="model.dob" format="DD-MM-YYYY" :rules="{required:true}"  :disabled-date="disableFutureDate"></validated-date-picker>
                </div>
</div>

            <div class="row">

                <div class="col-12">
                    <validated-input type="tel" class="field-required"  label="Phone Number" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="Phone" v-model="model.mobile_number" :rules=rules.phoneNumValidation></validated-input>
                </div>

                <div class="col-12 ">
                    <validated-input type="mail" class="field-required"  label="Email" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="Email" v-model="model.email" :rules="rules.emailValidation"></validated-input>
                </div>

                <div class="col-lg-12 ">
                    <validated-vue-select :options="designationOptions" class="field-required"  label="Designation" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Designation" v-model="model.designation" :rules="{required:true}"></validated-vue-select>
                </div>

            </div>
<!--            <div class="row mt-lg-6">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Add User" :loading="loading" loading-text="Saving..."  class="px-4" color="secondary"></btn>
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="col-12 text-right pr-0">
                            <btn text="Save" :loading="loading" loading-text="saving..." class="px-4"></btn>

                        </div>
        </s-form>

</div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'addUser',
    data () {
        return {
            URL: urls.auth.userAddEdit,
            loading: false,
            model: {
                first_name: '',
                last_name: '',
                gender: '',
                dob: '',
                mobile_number: '91',
                email: '',
                designation: ''
            },
            designationOptions: [
                { value: 'HR', label: 'HR' },
                { value: 'Team Lead', label: 'Team Lead' },
                { value: 'Frontend Developer', label: 'Frontend Developer' },
                { value: 'Junior Frontend Developer', label: 'Junior Frontend Developer' },
                { value: 'Backend Developer', label: 'Backend Developer' },
                { value: 'Junior Backend Developer', label: 'Junior Backend Developer' },
                { value: 'Project Manager', label: 'Project Manager' },
                { value: 'Tester', label: 'Tester' }
            ],
            genderOption: [
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' }
            ],
            rules: {
                phoneNumValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.isValidMobileNumber(value);
                    }
                },
                emailValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.isValidEmail(value);
                    }
                }

            }
        };
    },
    methods: {
        isValidEmail (email) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (emailRegex.test(email)) {
                return true;
            } else {
                return 'Please enter a valid email address';
            }
        },
        isValidMobileNumber (phoneNumber) {
            const minDigits = 10;
            const maxDigits = 13;

            if (phoneNumber.length < minDigits || phoneNumber.length > maxDigits) {
                return 'Enter a valid number (between ' + minDigits + ' and ' + maxDigits + ' digits)';
            }

            if (!/^\d+$/.test(phoneNumber)) {
                return 'Enter a valid number (only digits)';
            }

            return true;
        },

        disableFutureDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
        revert () {
            this.$router.push({ path: '/users/' });
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$emit('success');
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
