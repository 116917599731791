<template>
    <div class="card mt-4">
        <div class="fl-te-c">
            <h2 class="text-primary text-center font-poppins-semibold" style=" font-weight: 650;">PROJECTS</h2>
            <slot name="addButton">
                          <btn v-if="user.admin || user.designation==='Project Manager'" design="basic-b" class="btn float-right" icon="fa fa-plus" text="New Project" @click="addProj"></btn>
            </slot>
        </div>
        <div class="projectsData">
            <vue-table class="tableProj" :fields="fields" :url="URL"
                       title="Projects" search-placeholder="project name"
                       :per-page="10" ref="table">
                <template #addButton></template>
                <template slot="actions" slot-scope="props">
                    <div class="btn-group">
                        <btn size="sm" icon="fa fa-eye" @click="viewProject(props.rowData)" design="basic-a"></btn>
                        <btn size="sm" icon="fa fa-pencil" @click="editItem(props.rowData)"></btn>
                        <btn size="sm" icon="fa fa-trash" color="danger" @click="deleteItem(props.rowData.id)"></btn>
                        <btn size="sm" icon="fa fa-download" color="info" @click="downloadReport(props.rowData.id)"></btn>
                    </div>
                </template>

            </vue-table>
            <modal title="Edit Project" ref="editProj" :no-close-on-backdrop="true" width="40r" header-color="primary">
                <edit-project @success="formSuccess" :data="{...editingItem}"></edit-project>
            </modal>
            <modal title="Add Project" ref="addProj" :no-close-on-backdrop="true" width="40r" header-color="primary">
                <add-project @success="addFormSuccess"></add-project>
            </modal>
            <modal ref="downloadCheck" title="" :show-additional-buttons="true"
                   :no-close-on-backdrop="true" width="40r" header-color="" >
                <div class="d-flex">  <loading-animation></loading-animation><p class="ml-1"> Loading...</p></div>

            </modal>
            <!--        delete Modal-->

            <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp" :url="deleteURL" :params="{id : itemID}"
                          @response="deleteComplete" @okClicked="checkDelete">
                <p> Are you sure ?</p>
                <template #loading>
                    <div class="fl-x">
                        <loading-animation/>
                        <b class="ml-3">Please Wait...</b>
                    </div>
                </template>
            </delete-modal>
        </div>
    </div>
</template>

<script>
import EditProject from './editProject';
import DeleteModal from '../../commonComponents/deleteModel';
import urls from '../../../data/urls';
import axios from 'secure-axios';
import AddProject from './addProject';
import store from '../../../store/';

export default {
    name: 'List',
    components: { AddProject, DeleteModal, EditProject },
    data () {
        return {
            downloading: false,
            itemID: null,
            deleteURL: urls.projects.Delete,
            URL: urls.projects.List,
            editingItem: '',
            fields: [
                {
                    name: 'project_name',
                    title: 'Project Name',
                    titleClass: '',
                    dataClass: ''

                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''

                },
                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: '',
                    dataClass: ''

                }

            ],
            user: '',
            fieldData: []
        };
    },
    mounted () {
        this.getMenu();
    },

    methods: {
        getMenu () {
            this.user = store.getters.currentUser;
            console.log('store use', this.user);
        },
        addFormSuccess () {
            this.$refs.addProj.close();
            this.$refs.table.refreshTable();
        },
        async downloadReport (id) {
            try {
                console.log('id', id);
                const that = this;
                that.error = false;
                that.downloading = true;
                this.$refs.downloadCheck.show();
                const response = await axios.get(urls.projects.reportDownload + '?id=' + id);
                const json = response.data;
                if (json.error === false) {
                    that.downloading = false;
                    this.$refs.downloadCheck.close();
                    window.open(json.url);
                    this.$notify('Downloaded Successfully', 'Success', { type: 'success' });
                } else {
                    that.downloading = false;
                    this.$refs.downloadCheck.close();
                    that.error = true;
                    that.message = json.message;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.downloading = false;
            }
        },
        checkDelete () {
            console.log('In delete Modal delete buttpon where clicked');
        },
        formSuccess () {
            this.$refs.editProj.close();
            this.$refs.table.refreshTable();
        },
        addProj () {
            this.$refs.addProj.show();
        },
        editItem (data) {
            this.editingItem = [];
            this.editingItem = data;
            console.log('data from project table', data);
            this.$refs.editProj.show();
        },
        viewProject (data) {
            this.$router.push({ path: '/' + data.id + '/modules/' });
        },

        deleteItem (item) {
            this.itemID = item;
            console.log('on delete function', this.itemID);
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>
.tableProj {

}

.projectsData {
    background-color: #f8f9fa;
}
</style>
