<template>
    <div class="home of-h full-height p-5">
        <h2 class="text-primary text-center mb-6 font-poppins-semibold">HOME</h2>
        <div class="row justify-content-center align-items-center px-5">
            <div class="col-lg-3" v-if="user && user.admin">
                <dashboard-card-item to="/users/" text="Users"></dashboard-card-item>
            </div>
            <div class="col-lg-3">
                <dashboard-card-item to="/projects/" text="Projects"></dashboard-card-item>
            </div>
            <div class="col-lg-3" v-if="user && user.admin" >
                <dashboard-card-item to="/notifications/" text="Notification"></dashboard-card-item>
            </div>
<!--            <div class="col-lg-3" v-if="user && user.admin" >
                <dashboard-card-item to="/detail-add/" text="Detail Info"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-3" v-if="user && user.admin" >
                <dashboard-card-item to="/detail-edit/" text="Detail Edit"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-3" v-if="user && user.admin" >
                <dashboard-card-item to="/detail-view/" text="Detail View"></dashboard-card-item>
            </div>-->
        </div>
    </div>
</template>

<script>
import DashboardCardItem from './components/UI/DashboardCardItem';
import store from '../store';

export default {
    name: 'Home',
    components: { DashboardCardItem },
    data () {
        return {
            user: null
        };
    },
    methods: {
        getMenu () {
            this.user = store.getters.currentUser;
        }
    },
    mounted () {
        this.getMenu();
    }
};
</script>

<style scoped>
.full-height {
    height: 100vh;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}
</style>
