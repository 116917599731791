<template>
<div>
<div class="card mt-4 mr-4">
        <div class="fl-te-c">
            <h2 class="text-primary text-center font-poppins-semibold" style=" font-weight: 650;">Notifications</h2>
            <btn design="basic-b" class="btn float-right" icon="fa fa-plus" text="New Notification" @click="addItem"></btn>
        </div>
        <div class="projectsData">
            <vue-table class="tableProj" :fields="fields" :url="this.listURL"
                       title="Projects" search-placeholder="Notification name"
                       :per-page="10" ref="table">
                <template slot="actions" slot-scope="props">
                    <div class="btn-group">
                        <btn size="sm" icon="fa fa-eye" @click="viewItem(props.rowData)" design="basic-a"></btn>
                        <btn size="sm" icon="fa fa-pencil" @click="editItem(props.rowData)"></btn>
                        <btn size="sm" icon="fa fa-trash" color="danger" @click="deleteItem(props.rowData.id)"></btn>
<!--                        <btn size="sm" icon="fa  fa-eye" color="warning" design="basic-b" @click="watchedUsers(props.rowData.id)"></btn>-->
                    </div>
                </template>

            </vue-table>
            <modal title="Edit Notification" ref="edit" :no-close-on-backdrop="true" width="40r" header-color="primary">
                <notification-edit @success="formSuccess" :data="{...editingItem}"></notification-edit>
            </modal>
            <modal title="Add Notification" ref="add" :no-close-on-backdrop="true" width="40r" header-color="primary">
                <notification-add @success="addFormSuccess"></notification-add>
            </modal>
            <modal title="View Notification" ref="view" :no-close-on-backdrop="true" width="35r" header-color="primary">
                <view-notification :data="displayItem" @success="addFormSuccess"></view-notification>
            </modal>

            <modal title="Users Read Status" ref="received" :no-close-on-backdrop="true" width="45r" header-color="primary">
                <recieved-users></recieved-users>
            </modal>

            <!--        delete Modal-->

            <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp" :url="deleteURL" :params="{id : deletingItemId}"
                          @response="deleteComplete">
                <p> Are you sure ?</p>
                <template #loading>
                    <div class="fl-x">
                        <loading-animation/>
                        <b class="ml-3">Please Wait...</b>
                    </div>
                </template>
            </delete-modal>
        </div>
    </div>
</div>
</template>

<script>
import DeleteModal from '../../commonComponents/deleteModel';
import NotificationEdit from './notificationEdit';
import NotificationAdd from './notificationAdd';
import ViewNotification from './viewNotification';
import RecievedUsers from './receievedUsers';
import urls from '../../../data/urls';
export default {
    name: 'NotificationList',
    components: { RecievedUsers, ViewNotification, NotificationAdd, NotificationEdit, DeleteModal },
    data () {
        return {
            deletingItemId: '',
            deleteURL: urls.Notification.Delete,
            listURL: urls.Notification.List,
            displayItem: [],
            editingItem: '',
            fields: [
                {
                    name: 'title',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                }

            ]
            /* fieldData: [{ name: 'notify 1', description: 'sample 1' },
                { name: 'notify 2', description: 'sample 2' },
                { name: 'notify 3', description: 'sample 3' },
                { name: 'notify 4', description: 'sample 4' }
            ] */
        };
    },
    methods: {
        deleteComplete () {
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        addFormSuccess () {
            this.$refs.add.close();
            this.$refs.table.refreshTable();
        },
        formSuccess () {
            this.$refs.edit.close();
            this.$refs.table.refreshTable();
        },
        viewItem (item) {
            this.displayItem = item;
            this.$refs.view.show();
        },
        editItem (item) {
            this.editingItem = [];
            this.editingItem = item;
            this.$refs.edit.show();
        },
        addItem () {
            this.$refs.add.show();
        },
        deleteItem (item) {
            this.deletingItemId = item;
            this.$refs.deleteModal.show();
        },
        watchedUsers () {
            this.$refs.received.show();
        }

    }

};
</script>

<style scoped>

</style>
