<template>
<div  class="home of-h full-height p-5">
        <div class="card w-30r mx-auto mt-6 pt-6 pb-6">
           <s-form @submit="setPassword" key="'login" role="form" >
            <h3 class="text-primary text-center font-poppins-semibold fs-lg-2 mt-2">Reset Password</h3>

            <div class="mt-5">
                <validated-input label="Email" type="email" placeholder="Email" class="ml-4 w-25r field-required" v-model="model.email"  :rules="{required:true}"></validated-input>
            </div>
            <p class="text-secondary text-center font-poppins-regular mt-lg-4 fl-x-cc cursor-pointer text-primary" @click="$router.push('/login-view/')">
<!--                            <img src="../../assets/web/icons/arrow-left.svg" alt="" class="mr-2 icon-sm">-->
                            <span>Back to Login</span></p>
            <div class="text-center mt-4">
            <btn text="Reset" class="mt-4 px-4" size="md" :loading="loading" loading-text="Resetting..."></btn>
            </div>
               </s-form>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'ForgetPassword',
    data () {
        return {
            loading: false,
            URL: urls.auth.forgetPassword,
            rules: {
                confirmPass: {
                    required: true,
                    customValidator: (value) => {
                        return this.validationConfirmPassword(value);
                    }
                }

            },
            model: {
                email: ''
            }
        };
    },
    methods: {

        async setPassword () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    // this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/login/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
 card {
        height: 30rem;
    }
    a{
        text-decoration: none;
    }
</style>
