<template>
<div class="main">
        <s-form @submit="addItem">

            <div class="row">
                <div class="col-lg-12 m-2">
                    <validated-input  label="Module Name" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="data.module_name"  placeholder="Module Name"></validated-input>
                </div>

                <div class="col-lg-12 m-2">
                    <validated-text-area  label="Description" label-classes="font-weight-600" :rules="{required:true}"  class="field-required"  input-classes="mt-2" placeholder="Description" v-model="data.description"></validated-text-area>
                </div>

            </div>
            <div class="row mt-lg-6">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Submit" class="px-4" :loading="loading" loading-text="Saving..."></btn>

                        </div>
                    </div>
                </div>
            </div>
        </s-form>

</div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'editModule',
    props: {
        data: {
            type: Object
        },
        id: {

        },
        projectId: {

        },
        editDetail: {
            type: Boolean
        }
    },
    mounted () {
        console.log('id of project', this.id);
    },
    data () {
        return {
            URL: urls.Module.AddEdit,
            loading: false,
            updatedItems: ''
        };
    },
    methods: {
        async addItem () {
            try {
                this.loading = true;

                this.data.project = this.$route.params.project;

                /* this.data.project = this.id;
*/
                const response = await axios.form(this.URL, this.data);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    console.log('Edit Detail state', this.editDetail);
                    if (this.editDetail === true) {
                        this.updatedItems = response.data.data;
                        console.log('Updated Data', this.updatedItems);
                        this.$emit('successUpdate', this.updatedItems);
                    }
                    this.$emit('success');
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
