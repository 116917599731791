<template>
    <div class="card mt-4">
        <div class="fl-te-c">
            <h2 class="text-primary text-center font-poppins-semibold" style=" font-weight: 650;">USERS</h2>
            <btn design="basic-b" class="btn float-right" icon="fa fa-plus" text="New User" @click="addUser"></btn>
        </div>
        <div class="projectsData">
            <vue-table class="tableAPI" title="Users" :fields="fields" :url="URL"
                       search-placeholder="name"
                       :per-page="10" ref="table">

                <template slot="actions" slot-scope="props">
                    <div class="btn-group ml-3">
                         <btn size="sm" icon="fa fa-eye" @click="viewUser(props.rowData.id)" design="basic-a"></btn>
                        <btn color="warning"
                             size="sm" @click="resetPassword(props.rowData)" text=""
                             icon="fa fa-key">
                        </btn>
                        <btn size="sm" icon="fa fa-pencil" @click="editItem(props.rowData)"></btn>
                    </div>

                </template>

                <template slot="status" slot-scope="props">
                    <div v-if="props.rowData.user_status">
                        <btn size="sm" v-if="props.rowData.user_status" color="success"
                             @click="disableItem(props.rowData.username)"
                             icon="fa fa-toggle-off"></btn>
                        <!--                        <span> Active</span>-->
                    </div>
                    <div v-else>
                        <btn size="sm" color="danger"
                             @click="enableItem(props.rowData.username)"
                             icon="fa fa-toggle-on"></btn>
                        <!--                        <span> Inactive</span>-->
                    </div>
                </template>

            </vue-table>

            <!--            modals-->

            <disable-modal
                @response="statusSuccess" :url="statusUrl" :params="{username: userId}" ref="disable">
                <template #default>Are you sure ?</template>
                <template #loading>Please Wait...</template>
            </disable-modal>

            <enable-modal
                @response="statusSuccess" :url="statusUrl" :params="{username: userId}" ref="enable">
                <template #default>Are you sure ?</template>
                <template #loading>Please Wait...</template>
            </enable-modal>
            <reset-modal
                @response="resetSuccess" :url="passwordRest" :params="{username: change.username}" ref="reset">
                <template #default>
                    Are you sure want to reset the password for
                    <span v-if="change.name"> <b>{{ change.name }}</b></span>
                    <span v-else> <b>{{ change.username }}</b></span> ?
                </template>
                <template #loading>Please Wait...</template>
            </reset-modal>
             <modal ref="viewDetail" title="User Details" :no-close-on-backdrop="true" width="35r" header-color="primary">
                 <user-view :data="detailItem"></user-view>
             </modal>
            <modal title="Edit Details" ref="edit" :no-close-on-backdrop="true" width="40r" header-color="primary">
                <edit-user :data="{...editingItem}" @success="formSuccess"></edit-user>
            </modal>
            <modal title="Add User" ref="add" :no-close-on-backdrop="true" width="40r" header-color="primary">
                <add-user @success="formAddSuccess"></add-user>
            </modal>

            <!--        delete Modal-->

            <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp">
                <p> Are you sure ?</p>
                <template #loading>
                    <div class="fl-x">
                        <loading-animation/>
                        <b class="ml-3">Please Wait...</b>
                    </div>
                </template>
            </delete-modal>
        </div>
    </div>
</template>

<script>
import DeleteModal from '../../commonComponents/deleteModel';
import EditUser from './editUser';
import urls from '../../../data/urls';
import EnableModal from '../Modal/EnableModal';
import DisableModal from '../Modal/DisableModal';
import resetModal from '../Modal/resetModal';
import AddUser from './addUser';
import UserView from './userView';

export default {
    name: 'UserList',
    components: { UserView, AddUser, DisableModal, EnableModal, EditUser, DeleteModal, resetModal },
    data () {
        return {
            passwordRest: urls.auth.passwordReset,
            change: {
                username: '',
                name: ''
            },
            detailItem: '',
            URL: urls.auth.UserList,
            moduleInfo: {},
            editingItem: '',
            userId: '',
            statusUrl: urls.auth.statusChange,
            /*   statusOption: [
                { label: 'Active ', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' }
            ], */
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'username',
                    title: 'Username',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'email',
                    title: 'Email'

                },

                {
                    name: 'designation',
                    title: 'Designation',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: '__slot:status',
                    title: 'Status',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                }

            ]

        };
    },
    methods: {

        viewUser (item) {
            /* console.log('user details from list api', item);
            this.$refs.viewDetail.show();
            this.detailItem = '';
            this.detailItem = item; */
            this.$router.push({ path: '/details/' + item + '/' });
        },

        formSuccess () {
            this.$refs.edit.close();
            this.$refs.table.refreshTable();
        },
        formAddSuccess () {
            this.$refs.add.close();
            this.$refs.table.refreshTable();
        },
        addUser () {
            this.$refs.add.show();
        },
        editItem (data) {
            this.editingItem = [];
            this.editingItem = data;
            console.log('data from module table', data);
            this.$refs.edit.show();
        },
        viewAPI (data) {
            this.$router.push({ path: '/api_view/', query: data });
        },
        deleteItem () {
            this.$refs.deleteModal.show();
        },
        // status part

        statusSuccess (response) {
            this.$refs.enable.close();
            this.$refs.disable.close();
            const json = response;
            if (json.error === false) {
                this.$refs.table.refreshTable();
            } else {
                this.Failure(json);
            }
        },
        Failure (json) {
            console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type: 'warning',
                    duration: 3000,
                    permanent: false
                }
            );
        },
        async disableItem (item) {
            this.userId = item;
            console.log('Desabling user id', this.userId);
            console.log('Desabling item', item);
            this.$refs.disable.show();
        },
        async enableItem (item) {
            this.userId = item;
            console.log('enabling user id', this.userId);
            console.log('enabling item', item);
            this.$refs.enable.show();
        },
        resetSuccess (response) {
            this.$refs.reset.close();
            const json = response;
            console.log('json', json);
            if (json.error === false) {
                this.$notify('Password Updated', 'Success',
                    {
                        type: 'success',
                        duration: 3000,
                        permanent: false
                    });
                this.$refs.table.refreshTable();
            } else {
                this.deleteFailure(json);
            }
        },
        deleteFailure (json) {
            console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type: 'warning',
                    duration: 3000,
                    permanent: false
                }
            );
        },
        async resetPassword (item) {
            this.change.username = item.username;
            this.change.name = item.name;
            this.$refs.reset.show();
        }
    }
};
</script>

<style scoped>

</style>
