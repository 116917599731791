<template>
<div>

     <div class="main">
        <div class="card p-3" style="margin:-15px -1rem">
            <div class="list ">
                  <div class="fl-x fl-j-e">
            <btn class="btn float-right" type="button" design="basic-b" icon="fa fa-plus" text="ADD" @click="addData"></btn>
        </div>

                <vue-table  class="tableDoc" title="Documents" :fields="fieldsName" :url="documentURL"
                       search-placeholder="name"
                       :per-page="10" ref="doctable" :extra-params="{id : userId}">

                    <template slot="actions" slot-scope="props" ref="table">
                         <div class="btn-group">
                            <btn size="sm" icon="fa fa-pencil" type="button" @click="editItem(props.rowData)"></btn>
                            <btn size="sm" icon="fa fa-trash" type="button" color="danger" @click="deleteItem(props.rowData.id)"></btn>
                            <btn size="sm" icon="fa fa-eye" type="button" @click="downloadPdf(props.rowData.document)"></btn>

                             </div>
                    </template>
                </vue-table>

                <modal title="Add Document" ref="add" :no-close-on-backdrop="true" width="35r" header-color="primary">
                    <document-add :data="tableddata"  @success="SuccessForm"></document-add>
                </modal>
                                <delete-modal :url="deleteURL" ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp" :params="{id:deletingItem}"
                              @response="deleteComplete">
                    <p> Are you sure ?</p>
                    <template #loading>
                        <div class="fl-x">
                            <loading-animation/>
                            <b class="ml-3">Please wait while we delete the item...</b>
                        </div>
                    </template>
                </delete-modal>
              <modal title="Edit Document" ref="edit" :no-close-on-backdrop="true" width="35r" header-color="primary">
                    <document-edit :data="{...editingInfo}"  @success="SuccessEdit"></document-edit>
                </modal>
            </div>

        </div>

    </div>

</div>
</template>

<script>
import DocumentAdd from './documentAdd';
import DeleteModal from '../../commonComponents/deleteModel';
import DocumentEdit from './documentEdit';
import urls from '../../../data/urls';
export default {
    name: 'documentList',
    components: { DocumentEdit, DeleteModal, DocumentAdd },
    data () {
        return {
            deletingItem: '',
            deleteURL: urls.auth.DocumentDelete,
            userId: this.$route.params.id,
            documentURL: urls.auth.DocumentList,
            editingInfo: '',
            fieldsName: [
                {
                    name: 'name',
                    title: 'Document Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                }
            ],
            tableddata: [
                { name: 'Aadhaar' },
                { name: '10th' },
                { name: 'PanCard' },
                { name: 'Office Id' }
            ]
        };
    },
    methods: {
        SuccessEdit () {
            this.$refs.edit.close();
            this.$refs.doctable.refreshTable();
        },
        editItem (item) {
            this.editingInfo = [];
            this.editingInfo = item;
            this.$refs.edit.show();
        },
        deleteItem (item) {
            this.deletingItem = item;
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.deleteModal.close();
            this.$refs.doctable.refreshTable();
        },
        addData () {
            this.$refs.add.show();
            this.$refs.doctable.refreshTable();
        },
        downloadPdf (item) {
            if (item) {
                const pdfUrl = item;
                console.log('item', pdfUrl);

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.target = '_blank';
                link.download = item;

                this.$notify('Downloaded Successfully', 'Success', { type: 'success' });
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.$notify('Please Upload file', 'Success', { type: 'warning' });
            }
        },

        SuccessForm () {
            this.$refs.add.close();
            this.$refs.doctable.refreshTable();
        }
    }

};
</script>

<style scoped>

</style>
