import { render, staticRenderFns } from "./viewNotification.vue?vue&type=template&id=d8d417b8&scoped=true&"
import script from "./viewNotification.vue?vue&type=script&lang=js&"
export * from "./viewNotification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8d417b8",
  null
  
)

export default component.exports