<template>
<div class="main">
   <s-form class="p-3" @submit="addData">

       <div class="row">
           <div class="col-lg-4">
                    <validated-input  label="Field Name" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="field name" v-model="prams.field_name"></validated-input>
           </div>
           <div class="col-lg-4">
                    <validated-vue-select :options="dataTypesOption" label="Data Type" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="data types" v-model="prams.field_type"></validated-vue-select>
           </div>
           <div class="col-lg-4">
                    <validated-vue-select :options="requiredOption" label="Required" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="required" v-model="prams.required"></validated-vue-select>
           </div>

       </div>

       <div class="row">
            <div class="col-lg-4 ">
                    <validated-input type="number"  label="Max Length" color="light" class="field-required" :rules=rules.positiveOnly  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="max length" v-model="prams.max_length" ></validated-input>
           </div>
            <div class="col-lg-4 ">
                    <validated-input type="number"  label="Min Length" color="light" class="field-required" :rules=rules.positiveOnly  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="min length" v-model="prams.min_length" ></validated-input>
           </div>
            <div class="col-lg-4">
                    <validated-input label="Default Value" color="light"   label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="default value" v-model="prams.default_value" ></validated-input>
           </div>
       </div>
       <div class="row mt-lg-4">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Add" class="px-4" :loading="loading" loading-text="Saving..."></btn>

                        </div>
                    </div>
                </div>
            </div>
   </s-form>
</div>
</template>

<script>
export default {
    name: 'parameterAdd',
    props: {
        data: {
            type: Array
        },
        flagStatus: {
            type: Boolean
        }
    },
    data () {
        return {
            loading: false,
            dataArray: '',
            requiredOption: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' }
            ],
            dataTypesOption: [
                { label: 'int', value: 'int' },
                { label: 'short', value: 'short' },
                { label: 'long', value: 'long' },
                { label: 'float', value: 'float' },
                { label: 'double', value: 'double' },
                { label: 'byte', value: 'byte' },
                { label: 'char', value: 'char' },
                { label: 'boolean', value: 'boolean' },
                { label: 'string', value: 'string' },
                { label: 'array', value: 'array' },
                { label: 'object', value: 'object' }
            ],
            prams: {
                field_name: '',
                field_type: '',
                required: '',
                max_length: '',
                min_length: '',
                default_value: ''

            },
            rules: {
                positiveOnly: {
                    required: true,
                    customValidator: (value) => {
                        return this.avoidNegetive(value);
                    }
                }
            }
        };
    },
    methods: {

        avoidNegetive (no) {
            const num = no;
            if (num < 0) {
                return 'Please enter a valid number';
            }

            const max = parseInt(this.prams.max_length);
            const min = parseInt(this.prams.min_length);

            if (max < min) {
                return 'Max length must be higher Min Length than';
            }

            return true;
        },
        addData () {
            console.log('in add Modal of parameter', this.prams);
            if (this.flagStatus) {
                this.dataArray = [];
                console.log('flag status true');
                this.dataArray.push(this.prams);
                this.flagStatus = false;
                this.$emit('success', this.dataArray);
            } else {
                console.log('flag status false');
                this.dataArray = this.data;
                this.dataArray.push(this.prams);
                this.$emit('success', this.dataArray);
            }
            /* this.$emit('success', this.dataArray); */
            console.log('in add Modal of array', this.dataArray);
        }
    }
};
</script>

<style scoped>

</style>
