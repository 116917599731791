<template>
<div class="main">
  <div class="card">
     <table>
  <tr>
      <th class="text-primary  lhs"><h5>Name</h5></th>
    <th>:</th>
      <th class = "rhs">{{ data.name }}</th>
  </tr>
  <tr>
      <th class="text-primary  lhs"><h5>Gender</h5></th>
    <th>:</th>
      <th class = "rhs">{{ data.gender }}</th>
  </tr>
  <tr>
      <th class="text-primary  lhs"><h5>Date Of Birth</h5></th>
    <th>:</th>
      <th class = "rhs">{{ data.dob }}</th>
  </tr>
  <tr>
      <th class="text-primary  lhs"><h5>Phone Number</h5></th>
    <th>:</th>
      <th class = "rhs">{{ data.mobile_number }}</th>
  </tr>
  <tr>
      <th class="text-primary  lhs"><h5>Email</h5></th>
    <th>:</th>
      <th class = "rhs">{{data.email}}</th>
  </tr>
  <tr>
      <th class="text-primary  lhs"><h5>Designation</h5></th>
    <th>:</th>
      <th class = "rhs">{{ data.designation }}</th>
  </tr>
         <tr>
      <th class="text-primary  lhs"><h5>User Status</h5></th>
    <th>:</th>
      <th v-if="data.user_status" class="text-success rhs">Active</th>
             <th v-else class="text-danger rhs">Inactive</th>
  </tr>
</table>

  </div>
</div>
</template>

<script>
export default {
    name: 'UserView',
    props: {
        data: {
            type: Array
        }
    }
};
</script>

<style scoped>
.lhs{
    padding-right: 20px;
}
.rhs{
    padding-left: 20px;
}
</style>
