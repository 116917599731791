<template>
<div class="card">
        <s-form @submit="addItem" ref="form" class="">

            <div class="row">
                <div class="col-lg-12 ">
                    <validated-vue-select :options="levels" label="Level" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     :rules="{required:true}" placeholder="Select Level" v-model="data.name"></validated-vue-select>
                </div>
                <div class="col-lg-12 ">
                    <validated-input label="Course" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     :rules="{required:true}" placeholder="Course" v-model="data.course"></validated-input>
                </div>
                <div class="col-lg-12 ">
                    <validated-input label="Institution" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     :rules="{required:true}" placeholder="Institution" v-model="data.institution"></validated-input>
                </div>

            </div>
         <div class="col-12 text-right">
                            <btn text="Save" :loading="loading" loading-text="saving..." class="px-4"></btn>

             </div>
        </s-form>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'educationDetailsEdit',
    data () {
        return {
            addStatus: true,
            URL: urls.auth.EducationAddEdit,
            loading: false,
            levelData: this.data.name
        };
    },
    props: {
        data: {

        },
        levels: {
            type: Array
        },
        arrayData: {

        }

    },

    mounted () {
        console.log('data', this.data);
        console.log(' full data', this.arrayData);
    },
    methods: {
        checkDuplication (type) {
            console.log('In check duplication function');
            const existingItems = this.arrayData.filter(obj => obj.name === type);
            console.log('existingItems.length', existingItems.length);
            if (existingItems.length >= 1) {
                this.addStatus = false;
                this.$notify('Education level already exist.', 'Warning', { type: 'warning' });
            } else {
                this.addStatus = true;
            }
        },
        async addItem () {
            if (this.levelData !== this.data.name) {
                this.checkDuplication(this.data.name);
            }
            if (this.addStatus) {
                try {
                    this.loading = true;
                    this.data.user = this.$route.params.id;
                    const response = await axios.form(this.URL, this.data);
                    const result = response.data;
                    console.log('result', result);
                    if (result.success === true) {
                        this.$notify('Added Successfully', 'Success', { type: 'success' });
                        console.log('data after edit', result.data);
                        this.loading = false;
                        this.$emit('success', result.data);
                    } else {
                        const errors = response.data.errors;

                        for (const key in errors) {
                            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                                const errorMessage = `${key}:  ${errors[key]}`;
                                this.$notify(errorMessage, 'Warning', { type: 'warning' });
                            }
                        }
                        this.loading = false;
                    }
                } catch (error) {
                    this.$notify(
                        'Something Went Wrong..!!',
                        error.response.status + ' ' + error.response.statusText,
                        { type: 'danger' }
                    );
                    this.loading = false;
                }
            }
        }

    }
};
</script>

<style scoped>

</style>
