<template>
    <div>
        <div class="card mt-4">
            <view-proj v-if="projectId" :project-id="projectId"></view-proj>
        </div>
        <div class="card mt-2">
            <div class="fl-te-c">
                <h2 class="text-primary text-center font-poppins-semibold" style=" font-weight: 650;">MODULES</h2>
                <btn design="basic-b" class="btn float-right" icon="fa fa-plus" text="New Module" @click="addModule"></btn>

            </div>
            <div class="projectsData">
                <vue-table v-if="projectId" class="tableModule" title="Modules" :fields="fields" :url="listUrl"
                           search-placeholder="name"
                           :per-page="10" ref="table" :extra-params="{project_id : projectId}">
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group ml-2 ">
                            <btn size="sm" icon="fa fa-eye" @click="viewModule(props.rowData)" design="basic-a"></btn>
                            <btn size="sm" icon="fa fa-pencil" @click="editItem(props.rowData)"></btn>
                            <btn size="sm" icon="fa fa-trash" color="danger" @click="deleteItem(props.rowData.id)"></btn>
                        </div>
                    </template>
                </vue-table>
                <modal title="Edit Module" ref="editmodule" :no-close-on-backdrop="true" width="40r" header-color="primary">
                    <edit-module :data="{...editingItem}" :id="projectId" @success="formSuccess"></edit-module>
                </modal>
                <modal title="Add Module" ref="addmodule" :no-close-on-backdrop="true" width="40r" header-color="primary">
                    <add-module :id="projectId"  @success="addFormSuccess"></add-module>
                </modal>

                <!--        delete Modal-->

                <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp"
                              :url="deleteURL" :params="{id : itemId}"
                              @response="deleteComplete" @okClicked="checkDelete">
                    <p> Are you sure ?</p>
                    <template #loading>
                        <div class="fl-x">
                            <loading-animation/>
                            <b class="ml-3">Please wait while we delete the item...</b>
                        </div>
                    </template>
                </delete-modal>
            </div>
        </div>
    </div>
</template>

<script>
import EditModule from './editModule';
import ViewProj from '../Projects/viewProj';
import DeleteModal from '../../commonComponents/deleteModel';
import urls from '../../../data/urls';
import AddModule from './addModule';

export default {
    name: 'moduleList',
    components: { AddModule, DeleteModal, ViewProj, EditModule },
    data () {
        return {
            projectId: this.$route.params.project,
            deleteURL: urls.Module.Delete,
            listUrl: urls.Module.List,
            editingItem: '',
            fields: [
                {
                    name: 'module_name',
                    title: 'Module',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },

                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'text-center w-10r',
                    dataClass: 'text-center '
                }

            ],
            itemId: ''
        };
    },
    methods: {
        addFormSuccess () {
            this.$refs.addmodule.close();
            this.$refs.table.refreshTable();
        },
        formSuccess () {
            this.$refs.editmodule.close();
            this.$refs.table.refreshTable();
        },
        checkDelete () {
            console.log('In delete Modal delete button where clicked');
        },
        addModule () {
            this.$refs.addmodule.show();
        },
        editItem (data) {
            this.editingItem = [];
            this.editingItem = data;
            console.log('data for edit module table', data);
            this.$refs.editmodule.show();
        },
        viewModule (data) {
            console.log('data in module view', data.id);
            this.$router.push({ path: '/apis/' + data.id + '/' });
        },
        deleteItem (item) {
            this.itemId = item;
            console.log('on delete function', this.itemID);
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.$refs.table.refreshTable();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.deleteModal.close();
        }
    }
};
</script>

<style scoped>

</style>
