<template>
    <div>
        <div class="card mt-4">
            <view-module :data="this.$route.params.id"></view-module>
        </div>
        <div class="card mt-2">
            <div class="fl-te-c">
                <h2 class="text-primary text-center font-poppins-semibold" style=" font-weight: 650;">API</h2>
                <btn design="basic-b" class="btn float-right" icon="fa fa-plus" text="New API" @click="addAPI"></btn>

            </div>
            <div class="projectsData">
                <vue-table class="tableAPI" title="API" :fields="fields" :url="URL"
                           search-placeholder="name"
                           :per-page="10" ref="table" :extra-params="{module_id : moduleId}">
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <btn size="sm" icon="fa fa-eye" @click="viewAPI(props.rowData.id)" design="basic-a"></btn>
                            <btn size="sm" icon="fa fa-pencil" @click="editItem(props.rowData)"></btn>
                            <btn size="sm" icon="fa fa-trash" color="danger" @click="deleteItem(props.rowData.id)"></btn>

                        </div>
                    </template>

                </vue-table>
                <!--         <Modal title="Edit API" ref="editApi" :no-close-on-backdrop="true" width="40r" header-color="primary">
                            <edit-api :data="{...editingItem}"  @success="formSuccess"></edit-api>
                        </Modal>-->

                <!--        delete Modal-->

                <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp" :url="deleteURL" :params="{id : itemID}"
                              @response="deleteComplete" @okClicked="checkDelete">
                    <p> Are you sure ?</p>
                    <template #loading>
                        <div class="fl-x">
                            <loading-animation/>
                            <b class="ml-3">Please Wait...</b>
                        </div>
                    </template>
                </delete-modal>
            </div>
        </div>
    </div>
</template>

<script>
/* import ViewProj from '../Modules/viewModule'; */
import DeleteModal from '../../commonComponents/deleteModel';
/* import EditApi from './editApi'; */
import urls from '../../../data/urls';
import ViewModule from '../Modules/viewModule';

export default {
    name: 'ApiListing',
    components: { ViewModule, DeleteModal },
    mounted () {
        this.collectProjectData();
    },
    data () {
        return {
            moduleId: this.$route.params.id,
            deleteURL: urls.ApiSection.Delete,
            URL: urls.ApiSection.List,
            itemID: null,
            moduleInfo: '',
            editingItem: '',
            fields: [
                {
                    name: 'api_name',
                    title: 'API Name',
                    titleClass: '',
                    dataClass: 'font-weight-600'
                },
                {
                    name: 'api_url',
                    title: 'URL',
                    titleClass: '',
                    dataClass: ''
                },

                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'text-center w-15r',
                    dataClass: 'text-center'
                }

            ],
            fieldData: []
        };
    },
    methods: {
        /*  formSuccess () {
            this.$refs.editApi.close();
            this.$refs.table.refreshTable();
        }, */
        checkDelete () {
            console.log('In delete Modal delete button where clicked');
        },
        collectProjectData () {
            this.moduleInfo = this.$route.params.id;
            console.log('in module project info', this.moduleInfo);
        },
        addAPI () {
            this.$router.push({ path: '/api-add/' + this.moduleInfo + '/' });
        },
        editItem (data) {
            console.log('id', data.id);
            this.$router.push({ path: '/api-edit/' + this.moduleInfo + '/' + data.id + '/' });
        },
        viewAPI (data) {
            console.log('detailapi', data);
            this.$router.push({ path: '/api-view/' + data + '/' });
        },
        deleteItem (item) {
            this.itemID = item;
            console.log('on delete function', this.itemID);
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.$refs.table.refreshTable();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.deleteModal.close();
        }

    }
};
</script>

<style scoped>

</style>
