<template>
<div class="main pb-3 mt-4 mb-1">
    <div class="fl-j-c fl-x" v-if="!dataItems">
      <p>Please wait...</p>
          <loading-animation></loading-animation>
      </div>
<div class="card p-3" v-else>
    <h2 class="pb-3 ml-2 text-primary">Detail View</h2>
    <div class="basicinfo p-3">
      <div class="row">
         <div class="col-lg-3">
<profile-image-view :imageURL = dataItems.image></profile-image-view>
         </div>
          <div class="col-lg-9">
              <div class="fl-te-c">
                    <h4 class="pb-4 ml-4" style="color: #6c757d">Basic Details</h4>
                  <btn class="btn float-right" type="button" design="basic-a" icon="fa fa-pencil" text="Edit" @click="editData" style=" margin-top: -1.6rem"></btn>

              </div>
  <div class="row ml-3">
   <div class="col-3">
       <h5 class="head">First Name</h5>
       <h6 class="data">{{dataItems.first_name}}</h6>
   </div>
      <div class="col-3">
       <h5 class="head">Last Name</h5>
       <h6 class="data">{{dataItems.last_name}}</h6>
   </div>
      <div class="col-3">
       <h5 class="head">Phone Number</h5>
       <h6 class="data">{{dataItems.mobile_number}}</h6>
   </div>
      <div class="col-3">
       <h5 class="head">Email</h5>
       <h6 class="data">{{dataItems.email}}</h6>
   </div>
  </div>

    <div class="row ml-3 mt-3">
   <div class="col-3">
       <h5 class="head">Gender</h5>
       <h6 class="data">{{dataItems.gender}}</h6>
   </div>
      <div class="col-3">
       <h5 class="head">Date Of Birth</h5>
       <h6 class="data">{{dataItems.dob}}</h6>
   </div>
      <div class="col-3">
       <h5 class="head">Designation</h5>
       <h6 class="data">{{dataItems.designation}}</h6>
   </div>
        <div class="col-3">
       <h5 class="head">User Status</h5>
       <h6 class="data text-success" v-if="dataItems.user_status">ACTIVE</h6>
       <h6 class="data text-danger" v-else>INACTIVE</h6>
   </div>
  </div>

    <div class="row ml-3 mt-3">
        <div class="col-3">
       <h5 class="head">User Name</h5>
       <h6 class="data">{{ dataItems.username }}</h6>
   </div>
<!--   <div class="col-6">
       <h5 class="head">Address</h5>
       <h6 class="data">{{dataItems.address}}</h6>
   </div>-->
  </div>
          </div>
      </div>

        <div>
            <div class="fl-te-c">
            <h4 style="color: #6c757d" class="font-weight-500 mt-4">Education Details</h4>
                <btn class="btn float-right" type="button" design="basic-a" icon="fa fa-plus" text="ADD" @click="addEducation"></btn>
            </div>
            <loading-animation v-if="educationLoading"></loading-animation>
            <div v-for="(item,index) in educationArray" :key="index" v-else>
                <div class="card b-2 mt-4" >
                    <div class="fl-te-c">
                                          <h5>{{ item.name }}</h5>
                         <btn class="btn float-right" type="button" design="basic-b" icon="fa fa-pencil" text="Edit" @click="editEducation(item,index)"></btn>
                    </div>

                    <div class="row">
                     <div class="col-lg-6 mt-2">
                       <h5>Course</h5>
                      <h6 class="mt-3">{{item.course}}</h6>
                     </div>
                     <div class="col-lg-6 mt-2">
                        <h5>Institution</h5>
                        <h6 class="mt-3">{{item.institution}}</h6>
                     </div>
                    </div>
                </div>

            </div>
        </div>
</div>
</div>
          <modal title="Add Education Details" ref="add" :no-close-on-backdrop="true" width="35r" header-color="primary">
                    <education-detail-add :data="educationArray"  :levels="levelOptions" @success="SuccessForm"></education-detail-add>
                </modal>
    <modal title="Edit Basic Details" ref="editBasicDetails" :no-close-on-backdrop="true" width="70r" header-color="primary">
                    <basic-detail-edit  :data="{...dataItems}"  :levels="levelOptions" @success="BasicSuccessForm" ></basic-detail-edit>
                </modal>
          <modal title="Edit Education Details" ref="edit" :no-close-on-backdrop="true" width="35r" header-color="primary">
                    <education-details-edit :levels="levelOptions" :arrayData="educationArray" :data="{...editingItem}" @success="EditSuccessForm"></education-details-edit>
                </modal>
</div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import EducationDetailAdd from './educationDetailAdd';
import EducationDetailsEdit from './educationDetailsEdit';
import BasicDetailEdit from './basicDetailEdit';
import ProfileImageView from './profileImageView';
export default {
    name: 'basicDetailsView',
    components: { ProfileImageView, BasicDetailEdit, EducationDetailsEdit, EducationDetailAdd },
    props: {
        data: {
            type: Array
        }
    },
    data () {
        return {
            educationLoading: false,
            dataItems: '',
            loading: false,
            URL: urls.auth.UserDetails,
            EducationURL: urls.auth.EducationList,
            userId: this.$route.params.id,
            editingItem: '',
            editingIndex: '',
            levelOptions: [
                { label: '10th', value: '10th' },
                { label: 'Plus Two', value: 'Plus Two' },
                { label: 'Diploma', value: 'Diploma' },
                { label: 'Bachelors', value: 'Bachelors' },
                { label: 'Masters', value: 'Masters' },
                { label: 'PHD', value: 'PHD' },
                { label: 'Others', value: 'Others' }
            ],

            educationArray: [
            ]

        };
    },
    mounted () {
        this.fetchInfo();
        this.fetchEducationInfo();
    },
    methods: {
        editData () {
            this.$refs.editBasicDetails.show();
        },
        BasicSuccessForm (item) {
            this.dataItems = item;
            this.$refs.editBasicDetails.close();
        },
        SuccessForm (item) {
            console.log('adding Item', item);
            this.addToArray(item, this.educationArray);
            this.$refs.add.close();
        },
        addToArray (item, originalArray) {
            originalArray.push(item);
            console.log('final Array', originalArray);
        },
        EditSuccessForm (item) {
            console.log('item', item);
            this.replaceObjectInArray(this.educationArray, this.editingIndex, item);
            this.$refs.edit.close();
        },
        replaceObjectInArray (data, index, replacementObject) {
            this.educationLoading = true;
            if (index >= 0 && index < data.length) {
                data[index] = replacementObject;
                setTimeout(() => {
                    console.log('this in set time out for param loading....');
                    this.educationLoading = false;
                }, 10);
                console.log('Object replaced successfully!');
            } else {
                this.educationLoading = false;
                console.error('Invalid index provided!');
            }
        },
        addEducation () {
            this.$refs.add.show();
        },
        editEducation (item, index) {
            this.editingIndex = index;
            this.editingItem = [];
            this.editingItem = item;
            this.$refs.edit.show();
        },
        async fetchInfo () {
            try {
                const response = await axios.form(this.URL, { id: this.userId });
                this.loading = true;

                const result = response.data;
                this.dataItems = result.data;
                console.log('result', this.dataItems);
                if (this.dataItems) {
                    this.loading = false;

                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchEducationInfo () {
            try {
                const response = await axios.get(this.EducationURL + '?id=' + this.userId);
                this.loading = true;

                const result = response.data;
                this.educationArray = result.data;
                console.log('result', this.dataItems);
                if (this.dataItems) {
                    this.loading = false;

                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.basicinfo{
     background-color: #cbe6f5;
}
</style>
