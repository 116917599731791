<template>
<div class="main p-2">
    <div class="row">
        <div v-for="(item,index) in userlist" :key="index" class="col-lg-4 mt-2" >
     <div class="card" id="detailCard">
       <h5>{{item.name}} </h5>
          <p> {{item.designation}}</p>
          <p class="text-success" v-if="item.readFlag">Read</p>
          <p class="text-warning" v-else>Recieved</p>

     </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'recievedUsers',
    data () {
        return {
            userlist: [
                { name: 'Anil', designation: 'FrontEnd developer', readFlag: false },
                { name: 'Jhons', designation: 'BackEnd developer', readFlag: true },
                { name: 'Anil', designation: 'HR', readFlag: true },
                { name: 'Nikil', designation: 'Manager', readFlag: true },
                { name: 'Hari', designation: 'Manager', readFlag: false },
                { name: 'kayse', designation: 'Manager', readFlag: true }
            ]
        };
    }

};
</script>

<style scoped>
#detailCard{
    background-color: #edf4f7;
    height: 8rem
}
</style>
