<template>
   <div class="pt-4">
<div class="main m-2 card">
        <s-form @submit="addItem" ref="form">

            <div class="row">
                <div class="col-12">
                    <h3 class="text-primary fs-lg-4 font-poppins-semibold" style=" font-weight: 650;">Edit API</h3>
                </div>
                </div>
            <div class="row">
                <div class="col-lg-12 ">
                    <validated-input  label="Module Name" class="field-required" :rules="{required:true}" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="apiData.api_name"  placeholder="Project Name"></validated-input>
                </div>

                 <div class="col-lg-12 ">
                    <validated-input  label="URL" color="light" class="field-required" :rules="{required:true}" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="apiData.api_url"  placeholder="Enter URL"></validated-input>
                </div>
                <div class="col-lg-12 ">
                    <validated-vue-select :options="methodOption" class="field-required" :rules="{required:true}"  label="Method" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="apiData.api_method"  placeholder="methods"></validated-vue-select>
                </div>

                 <div class="col-lg-12 m-2">
<!--                     <label class="font-weight-700">Parameter</label>-->
                     <loading-animation v-if="!tableddata"></loading-animation>
                    <parameter-list-edit v-else :data="tableddata" @successList="catchData"></parameter-list-edit>
                </div>

                <div class="col-lg-12 ">
                    <validated-text-area  label="Success Response" class="field-required" :rules="{required:true}" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Response" v-model="apiData.success_response"></validated-text-area>
                </div>
<!--                <div class="col-lg-12 ">
                    <validated-text-area  label="Error Response" class="field-required" :rules="{required:true}" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Response" v-model="apiData.response"></validated-text-area>
                </div>-->

            </div>

                  <div class="row" v-for="(errorRes, index) in apiData.error_response_array" :key="index">
                     <div class="col-12 m-2">
                    <validated-text-area  label="Error Response" class="field-required" :rules="{required:true}" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Response" v-model="errorRes.response"></validated-text-area>
                </div>
                <div class="col-lg-2 d-flex">
                    <btn text="Add Response" v-if="index === apiData.error_response_array.length - 1"
                         type="button" icon="fa fa-plus"
                         @click="addResponce(index, errorRes.response)"
                         class="mt-lg-4 ml-2"></btn>
                    <div v-if="removeStatus">
                    <btn text="Remove Response" v-if="index === apiData.error_response_array.length - 1"
                         type="button" icon="fa fa-minus"
                         @click="removeResponce"
                         class="mt-lg-4 ml-2"></btn>
                        </div>
                </div>

            </div>

            <div class="row mt-lg-5">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Submit" class="px-4" :loading="loading" loading-text="Saving..."></btn>

                        </div>
                    </div>
                </div>
            </div>
        </s-form>

</div>
        </div>

</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import ParameterListEdit from './parameterListEdit';
export default {
    components: { ParameterListEdit },
    name: 'detailEditAPI',
    data () {
        return {

            moduleId: this.$route.params.module,
            projectId: this.$route.params.project,
            removeStatus: true,
            pramlist: '',
            apiData: '',
            tableddata: '',
            apiId: this.$route.params.id,
            paramList: this.data,
            data: '',
            ApiInfo: '',
            fetchedData: '',
            detailUrl: urls.ApiSection.Details,
            URL: urls.ApiSection.AddEdit,
            loading: false,
            methodOption: [
                { value: 'POST', label: 'POST' },
                { value: 'GET', label: 'GET' },
                { value: 'PATCH', label: 'PATCH' },
                { value: 'DELETE', label: 'DELETE' },
                { value: 'PUT', label: 'PUT' },
                { value: 'HEAD', label: 'HEAD' },
                { value: 'OPTIONS', label: 'OPTIONS' }
            ]
        };
    },
    mounted () {
        this.collectData();
        console.log('Api deatils in api editing mounted', this.data);
        console.log('Api editing params', this.data.params);
        this.detailsfetch();
    },
    methods: {
        /*   initialCheckForErrorResponse () {
            if (this.apiData.error_response_array.length > 1) {
                this.removeStatus = true;
            } else {
                console.log('initial check else hitted ');
                this.removeStatus = false
            }
        }, */
        removeResponce () {
            if (this.apiData.error_response_array.length > 1) {
                this.removeStatus = true;
                this.apiData.error_response_array.pop();
            } else {
                console.log('else statement hitt');
                this.apiData.error_response_array = [''];
                this.removeStatus = false;
            }
        },
        addResponce (index, res) {
            this.removeStatus = true;
            console.log('err arry', this.apiData.error_response_array);
            console.log('err arry length', this.apiData.error_response_array.length);
            console.log('err arry type', typeof this.apiData.error_response_array);
            this.apiData.error_response_array.push({ response: '' });
            this.apiData.error_response_array[index].response = res;

            this.removeStatus = true;
        },
        isJson (str) {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        },
        convert () {
            console.log('in submit button', this.pramlist);
            console.log('dataTYpe', typeof this.pramlist);
            const myJSON = JSON.stringify(this.pramlist);
            const myJSON2 = JSON.stringify(this.apiData.error_response_array);
            this.isJson(myJSON);
            this.isJson(myJSON2);
            this.apiData.module = this.$route.params.module;
            console.log('dataTYpe o concerted data', this.isJson(myJSON));
            console.log('dataTYpe of response', this.isJson(myJSON2));
            console.log('dataTYpe', this.isJson(myJSON));
            console.log('dataTYpe response', this.isJson(myJSON2));

            this.apiData.parameters = myJSON;
            this.apiData.error_response = myJSON2;
            console.log('after conversion', this.apiData);
        },
        catchData (item) {
            console.log('item in add api for arry', item);
            this.pramlist = item;
        },

        stringConversion () {
            this.tableddata = JSON.parse(this.fetchedData);
            console.log('dataTYpe', typeof this.tableddata);
            console.log('after convert to array', this.tableddata);
        },
        async detailsfetch () {
            try {
                this.loading = true;
                console.log('apiId', this.apiId);
                const response = await axios.form(this.detailUrl, { id: this.apiId });
                const result = response.data;
                console.log('result', result);
                this.apiData = result.data;
                console.log('api data', this.apiData);

                this.fetchedData = result.data.parameters;

                console.log('fetched Data', this.fetchedData);

                this.stringConversion();

                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        collectData () {
            this.ApiInfo = this.$route.params.id;
            console.log('in Api edit info', this.ApiInfo);
            this.data = this.ApiInfo;
        },
        async addItem () {
            try {
                this.loading = true;
                this.convert();
                console.log('api data in edit api submit', this.apiData);
                const response = await axios.form(this.URL, this.apiData);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$router.push({ path: '/api-view/' + this.apiData.id + '/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.apiData.error_response = [''];
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.apiData.error_response = [''];
                this.loading = false;
            }
        }
    }

};
</script>

<style scoped>

</style>
