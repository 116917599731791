<template>
<div class="main">
   <s-form class="p-3" @submit="editData">

       <div class="row">
           <div class="col-lg-4">
                    <validated-input  label="Field Name" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="field name" v-model="data.field_name"></validated-input>
           </div>
           <div class="col-lg-4">
                    <validated-vue-select :options="dataTypesOption" label="Data Type" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="data types" v-model="data.field_type"></validated-vue-select>
           </div>
           <div class="col-lg-4">
                    <validated-vue-select :options="requiredOption" label="Required" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="required" v-model="data.required"></validated-vue-select>
           </div>

       </div>

       <div class="row">
            <div class="col-lg-4 ">
                    <validated-input type="number"  label="Max Length" color="light" class="field-required" :rules="rules.positiveOnly"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="max length" v-model="data.max_length" ></validated-input>
           </div>
            <div class="col-lg-4 ">
                    <validated-input type="number"  label="Min Length" color="light" class="field-required" :rules="rules.positiveOnly"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="min length" v-model="data.min_length" ></validated-input>
           </div>
            <div class="col-lg-4">
                    <validated-input label="Default Value" color="light" class=""  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="default value" v-model="data.default_value" ></validated-input>
           </div>
       </div>
       <div class="row mt-lg-4">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Submit" class="px-4" :loading="loading" loading-text="Saving..."></btn>

                        </div>
                    </div>
                </div>
            </div>
   </s-form>
</div>
</template>

<script>
export default {
    name: 'parameterEdit',
    props: {

        data: {
            type: Object
        },
        arrayData: {
            type: Array
        },
        index: {

        }
    },
    data () {
        return {
            loading: false,
            dataArray: this.data,
            dataCopy: { ...this.data },
            requiredOption: [
                { label: 'True', value: 'True' },
                { label: 'False', value: 'False' }
            ],
            dataTypesOption: [
                { label: 'int', value: 'int' },
                { label: 'short', value: 'short' },
                { label: 'long', value: 'long' },
                { label: 'float', value: 'float' },
                { label: 'double', value: 'double' },
                { label: 'byte', value: 'byte' },
                { label: 'char', value: 'char' },
                { label: 'boolean', value: 'boolean' },
                { label: 'string', value: 'string' },
                { label: 'array', value: 'array' },
                { label: 'object', value: 'object' }
            ],
            rules: {
                positiveOnly: {
                    required: true,
                    customValidator: (value) => {
                        return this.avoidNegetive(value);
                    }
                }
            }
            /* model: {
                field_name: '',
                data_type: '',
                required: '',
                max_length: '',
                min_length: '',
                default_value: 'null'

            } */
        };
    },
    mounted () {
        console.log('edit Modal - index', this.index);
        console.log('edit Modal - array', this.arrayData);
        console.log('edit Modal - data', this.data);
    },

    methods: {
        avoidNegetive (no) {
            const num = no;
            if (num < 0) {
                return 'Please enter a valid number';
            }

            const max = parseInt(this.data.max_length);
            const min = parseInt(this.data.min_length);

            if (max < min) {
                return 'Max length must be higher Min Length than';
            }

            return true;
        },
        editData () {
            this.edit_state = true;
            this.$emit('editStateChange');
            console.log('edit Modal - index', this.index);
            console.log('edit Modal - array', this.arrayData);
            console.log('edit Modal - datacopy', this.dataCopy);
            this.dataArray = this.replaceObjectAtIndex(this.arrayData, this.index, this.data);
            console.log('edit Modal - final array after edit', this.dataArray);

            this.$emit('success', this.dataArray);
        },
        replaceObjectAtIndex (array, index, newObject) {
            try {
                // Check if the index is within the array bounds
                if (index >= 0 && index < array.length) {
                    array[index] = newObject;
                    return array; // Return the modified array
                } else {
                    throw new Error('Invalid index');
                }
            } catch (error) {
                console.error('Error:', error.message);
                return array; // Return the original array if an error occurs
            }
        }

    }
};
</script>

<style scoped>

</style>
