<template>
<div class="card">
        <s-form @submit="addItem" ref="form" class="">

            <div class="row">
                <div class="col-lg-12 ">
                    <validated-vue-select :options="documentOptions" label="Document Name" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"
                                     :rules="{required:true}" v-model="data.name" placeholder="Document Name"></validated-vue-select>
                </div>

                <div class="col-lg-12 ">
                    <validated-file-input label="File" v-model="data.document" label-text="Choose file" @input="docNameUpdate" :rules="{required: false}"></validated-file-input>
                  <span style="font-size: 0.9rem;color: blue;" v-if="showDocName">{{data.document}}</span>
                </div>

            </div>
         <div class="col-12 text-right">
                            <btn text="Save" :loading="loading" loading-text="saving..." class="px-4"></btn>

             </div>
        </s-form>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'documentEdit',
    data () {
        return {
            showDocName: true,
            docname: '',
            URL: urls.auth.DocumentAddEdit,
            loading: false,
            documentOptions: [
                { label: 'Aadhaar', value: 'Aadhaar' },
                { label: 'Pan Card', value: 'Pan Card' },
                { label: 'Voter ID', value: 'Voter ID' },
                { label: 'Driving License', value: 'Driving License' },
                { label: '10th', value: '10th' },
                { label: 'Plus Two', value: 'Plus Two' },
                { label: 'Diploma', value: 'Diploma' },
                { label: 'Bachelors', value: 'Bachelors' },
                { label: 'Masters', value: 'Masters' },
                { label: 'PHD', value: 'PHD' }
            ]
            /* model: {
                module_name: '',
                description: '',
                project: this.id
            } */
        };
    },
    props: {
        data: {

        }
    },

    mounted () {
        console.log('data', this.data);
    },
    methods: {
        docNameUpdate () {
            this.docname = this.data.document.[0].name;
            this.showDocName = false;
        },
        async addItem () {
            try {
                this.loading = true;
                this.data.user = this.$route.params.id;
                const response = await axios.form(this.URL, this.data);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$emit('success');
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }

    }
};
</script>

<style scoped>

</style>
