<template>
    <div class="card" style="margin: -1rem;">
        <s-form @submit="addItem" ref="form" class="">

            <div class="row ">
                <div class="col-12">
                    <validated-input label="Notification Name" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2" placeholder="Notification Name"
                                      :rules="{required:true}" v-model="model.title"></validated-input>
                </div>

                <div class="col-12">
                    <validated-text-area label="Description" label-classes="font-weight-600" input-classes="mt-2" placeholder="Description"
                                         :rules="{required:true}" class="field-required" v-model="model.description"></validated-text-area>
                </div>
<!--                <div class="col-12">
                    <validated-vue-select :options="recipientOption" class="c-input-select field-required input-border-primary"
                                           label-classes="font-weight-500" input-classes="mt-2" multiple
                                           :rules="{required:true}" label="Recipients"
                                           ></validated-vue-select>
                </div>-->

            </div>
            <div class="col-12 text-right pr-0">
                <btn text="Save" :loading="loading" loading-text="saving..." class="px-4"></btn>

            </div>
        </s-form>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'notificationAdd',
    data () {
        return {
            loading: false,
            recipientOption: [],
            userUrl: urls.projects.UsersVueSelect,
            URL: urls.Notification.AddEdit,
            model: {
                title: '',
                description: ''
            }

        };
    },
    mounted () {
        this.loadRecipient();
    },

    methods: {
        async loadRecipient () {
            const response = await axios.get(urls.projects.UsersVueSelect);
            this.recipientOption = response.data.data;
            this.recipientOption.push({ label: 'All', value: '' });
            console.log('bull option', this.recipientOption);
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$emit('success');
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
