<template>
    <div class="card" style="margin: -1rem;">
        <s-form @submit="addItem" ref="form" class="">

            <div class="row ">
                <div class="col-12">
                    <validated-input label="Project Name" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2" placeholder="Project Name"
                                     v-model="model.project_name" :rules="{required:true}"></validated-input>
                </div>

                <div class="col-12">
                    <validated-text-area label="Description" label-classes="font-weight-600" input-classes="mt-2" placeholder="Description" v-model="model.description"
                                         :rules="{required:true}" class="field-required"></validated-text-area>
                </div>
                <div class="col-12">
                    <validated-ajax-select :url="userUrl" class="c-input-select field-required input-border-primary"
                                           label-classes="font-weight-500" input-classes="mt-2" multiple
                                           placeholder="Team members" :rules="{required:true}" label="Team Members"
                                           v-model="model.team_members"></validated-ajax-select>
                </div>

            </div>
            <div class="col-12 text-right pr-0">
                <btn text="Save" :loading="loading" loading-text="saving..." class="px-4"></btn>

            </div>
        </s-form>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'addProject',
    data () {
        return {
            userUrl: urls.projects.UsersVueSelect,
            URL: urls.projects.AddEdit,
            loading: false,
            model: {
                project_name: '',
                description: '',
                team_members: ''
            }
        };
    },
    methods: {
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.$emit('success');
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        revert () {
            this.$router.push({ path: '/projects/' });
        }
    }
};
</script>

<style scoped>

</style>
