<template>
    <div class="home of-h full-height">
        <div class="center-container">
            <div class="card">
                <div class="logo text-center">
                    <img src="@/assets/img/logo/logo_only.png" alt="" class="logo-img">
                </div>
                <div class="text-center mt-0">
                    <h3 class="text-center text-primary fs-lg-2 font-poppins-semibold">Xeoscript Technologies</h3>
                </div>
                <div class="form-container">
                    <s-form key="'login" role="form" @submit="loginClicked" class="contact-form">
                        <h4 class="text-primary text-center font-poppins-semibold fs-lg-2 mb-3">LOGIN</h4>
                        <div class="input-group mb-3">
                            <validated-input icon="fa fa-user text-primary" placeholder="Username" class="field-required" :rules="{required:true}"
                                             v-model="model.username"></validated-input>
                        </div>
                        <div class="input-group mb-3">
                            <validated-input icon="fa fa-lock text-primary" type="password" placeholder="Password" class="field-required" :rules="{required:true}"
                                             v-model="model.password"></validated-input>
                        </div>
                        <div class="error-message" v-if="errorMessage" v-html="errorMessage"></div>
<!--                        <div class="forgot-password-link">
                            <a class="text-primary font-poppins-medium fs-lg-0" @click="$router.push('/reset-password/')">Forgot Password?</a>
                        </div>-->
                        <div class="text-center">
                            <btn text="Login" class="mt-4 px-4"></btn>
                        </div>
                    </s-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '../../data/urls';
import { mapActions } from 'vuex';
import axios from 'secure-axios';

export default {
    name: 'LoginView',
    data () {
        return {
            loading: false,
            errorMessage: '',
            urls: urls.auth.login,
            model: {
                username: '',
                password: ''
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        async loginClicked () {
            try {
                this.loading = true;
                const response = await axios.form(this.urls, this.model);
                const json = response.data;
                if (json.user) {
                    this.setUser(json.user);
                    if (!json.user.password_flag && !json.user.admin) {
                        await this.$router.push({ path: '/initial-reset-password/' });
                    } else {
                        await this.$router.push({ path: '/' });
                        window.location.reload(true);
                    }
                } else {
                    this.errorMessage = 'Invalid Credentials';
                }
            } catch (error) {
                console.error('Error during login:', error);
                this.errorMessage = 'An error occurred while processing your request.';
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.home {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.card {
    width: 30rem;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.logo-img {
    width: 150px; /* Adjust as needed */
}

.form-container {
    margin-top: 2rem;
}

.input-group {
    position: relative;
}

.input-group .field-required {
    width: 100%;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 0.75rem;
    border-radius: 0.25rem;
    text-align: center;
    margin-top: 1rem;
}

.forgot-password-link {
    text-align: right;
    margin-top: 0.5rem;
}

.btn {
    margin-top: 2rem;
}

.btn button {
    width: 100%;
}
</style>
