<template>
    <div class="main">
        <div class="card">
            <div class="list">

                <loading-animation v-if="editLoading"></loading-animation>
                <div v-else>
                     <div class="fl-te-c">
            <h5 class="text-center font-poppins-semibold" style="margin-left: -1rem;">Parameter</h5>
            <btn class="btn float-right" type="button" design="basic-b" icon="fa fa-plus" text="ADD" @click="addData"></btn>
            </div>
                <simple-table :fields="fieldsName" ref="paramsTable" :data="converteditems"
                           :show-search-box="false" :show-pagination="false" class="paramTable">
                    <template slot="actions" slot-scope="props" >

                        <div class="btn-group ml-3">
                            <btn size="sm" icon="fa fa-pencil" type="button" @click="editItem(props.rowData)"></btn>

                        </div>

                        <div class="btn-group  ml-3">
                            <btn size="sm" icon="fa fa-trash" type="button" color="danger" @click="deleteItem(props.rowData)"></btn>

                        </div>
                    </template>
                </simple-table>
                    </div>

                <modal title="Add Parameter" ref="editAddparams" :no-close-on-backdrop="true" width="60r" header-color="primary">
                    <parameter-add :data="converteditems" :flagStatus="dataFlag" @success="SuccessForm"></parameter-add>
                </modal>
                <modal title="Edit Parameter"  ref="editEditparams" :no-close-on-backdrop="true" width="60r" header-color="primary">
                    <parameter-edit :data="editingInfo"  :arrayData="converteditems" :index="indexArray" @editStateChange="changeStateEdit" @success="SuccessEdit"></parameter-edit>
                </modal>
                <modal title="Are you sure ?" footer-color="light" ref="editDelete" :no-close-on-backdrop="false" width="40r" header-color="danger">
                    <CustomDelete :index="indexArray" @buttonClickedCancel="CancelClicked" @buttonClickedOK="okClicked"></CustomDelete>
                </modal>
            </div>

        </div>

    </div>
</template>

<script>
import CustomDelete from './customDelete';
import ParameterEdit from './parameterEdit';
import ParameterAdd from './parameterAdd';
export default {
    name: 'parameterListEdit',
    components: { ParameterAdd, ParameterEdit, CustomDelete },
    props: {
        data: {

        }

    },

    data () {
        return {
            dataFlag: '',
            editLoading: false,
            converteditems: this.data,
            indexArray: '',
            dataEditing: '',
            editingInfo: '',
            dataLoading: false,
            fieldsName: [
                {
                    name: 'field_name',
                    title: 'Field Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'field_type',
                    title: 'Data Type',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'required',
                    title: 'Required',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'max_length',
                    title: 'Max Length',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: 'min_length',
                    title: 'Min Length',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: 'default_value',
                    title: 'Default Value',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: '__slot:actions',
                    title: 'Action',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                }
            ]
        };
    },
    mounted () {
        this.initialInitialization();
        this.initialCall();
    },
    methods: {
        initialInitialization () {
            console.log('data of inialization', this.data);
            if (this.data) {
                this.dataFlag = false;
                console.log('if hitted', this.dataFlag);
            } else {
                this.dataFlag = true;
                console.log('else hitted', this.dataFlag);
            }
        },
        initialCall () {
            console.log('initial Call edit api prams', this.converteditems);
            console.log('edit status', this.editLoading);
            this.$emit('successList', this.converteditems);
        },
        changeStateEdit () {
            this.editLoading = true;
        },
        deleteObjectAtIndex (array, index) {
            if (index >= 0 && index <= array.length) {
                array.splice(index, 1);
            }
        },
        CancelClicked () {
            this.$refs.editDelete.close();
            this.$emit('successList', this.converteditems);
        },
        okClicked (item) {
            console.log('index in ok clicked', this.indexArray);
            this.deleteObjectAtIndex(this.converteditems, item);
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.editDelete.close();

            console.log('params in prm_list', this.converteditems);
            this.$emit('successList', this.converteditems);

            this.$refs.paramsTable.refreshTable();
        },
        editItem (item) {
            /* this.editingInfo = []; */

            console.log('Editing item', item);
            this.dataEditing = item;
            this.editingInfo = { ...item };
            this.indexArray = this.converteditems.indexOf(this.dataEditing);
            this.$refs.editEditparams.show();
        },

        addData () {
            this.$refs.editAddparams.show();
        },
        SuccessForm (item) {
            console.log('In Param List Successform', item);
            this.dataFlag = false;
            this.converteditems = item;
            this.$notify('Parameter Added Successfully', 'Success', { type: 'success' });
            this.$refs.editAddparams.close();

            console.log('params in prm_list', this.converteditems);
            this.$emit('successList', this.converteditems);

            this.$refs.paramsTable.refreshTable();
        },
        SuccessEdit (data) {
            this.$refs.editEditparams.close();
            this.converteditems = [];

            this.converteditems = data;
            setTimeout(() => {
                this.$notify('Parameter Updated Successfully', 'Success', { type: 'success' });

                this.editLoading = false;
            }, 30);
            console.log('params in prm_list', this.converteditems);
            console.log('IN Successs Edit - after edit array', this.converteditems);
            this.$emit('successList', this.converteditems);

            this.$refs.paramsTable.refreshTable();
        },
        replaceObjectAtIndex (array, index, newObject) {
            try {
                // Check if the index is within the array bounds
                if (index >= 0 && index < array.length) {
                    array[index] = newObject;
                    return array; // Return the modified array
                } else {
                    throw new Error('Invalid index');
                }
            } catch (error) {
                console.error('Error:', error.message);
                return array; // Return the original array if an error occurs
            }
        },
        deleteItem (data) {
            this.$refs.editDelete.show();
            this.indexArray = this.converteditems.indexOf(data);
            /* if (status === true) {
                this.converteditems.pop()
            } */
        }

    }
};
</script>

<style scoped>
.paramTable{
margin-left: -1.8rem
}
</style>
