<template>
<div class="d-flex fl-y">

    <div class="p-2">
         <div v-if="imgUrlStatus">
        <img :src="imgUrl" alt="Preview" />
      </div>
        <div v-else>
       <img src="../../../assets/img/PICA.jpg" alt="Avatar" class="image" style="width:100%" v-if="!imageURL">
      <img :src="imageURL" alt="Avatar" class="image" style="width:100%" v-else>
        <span style="font-size: 0.9rem;color: blue;">{{imgName}}</span>
        </div>
      <div class="middle mt-5" style="width: 18rem;margin-left:2.3rem ">
        <div class="" style="cursor: pointer">
<!--          <btn text="" class="fa fa-camera"  type="button" style="font-size:48px;color:white" @click="OpenImageModal"></btn>-->
 <validated-file-input
                  label="" class="bg-primary" v-model="imageURL"
                  label-text="Upload" type="file" @input="handleSubmit" icon="fa fa-camera" ref="imageInput"></validated-file-input>
        </div>
        </div>

    </div>

  </div>
</template>

<script>

export default {
    name: 'profileImage',
    props: {
        imageURL: {
            type: String,
            default: ''
        },
        animal: {
            type: String

        }
    },
    data () {
        return {
            imgUrl: this.imageURL,
            imgUrlStatus: false,
            loading: false,
            imgName: '',
            model: {
                image: ''
            },
            errorMessage: false

        };
    },
    methods: {
        handleSubmit () {
            console.log(' handle submit');

            const file = this.$refs.imageInput.files[0];
            if (!file) {
                console.error('Please select an image file to upload.');
                return;
            }

            // Optional validation: Check file size, type, etc.

            // Convert the file to a data URL for preview
            const reader = new FileReader();
            reader.onload = (e) => {
                console.log('inside handle submit');

                this.imgUrl = e.target.result;
                this.imgUrlStatus = true;
            };
            reader.readAsDataURL(file);
            this.$emit('success', this.imageURL);

            //   this.$refs.imageModal.close();
        },

        OpenImageModal () {
            this.$refs.imageModal.show();
        },
        async UploadImage () {
            console.log('image url', this.model.image);
            this.handleSubmit();
            this.$refs.imageModal.close();
        }
    }
};
</script>

<style scoped>
.image {
  opacity: 1;
  display: block;
  max-width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
img {
      max-width: 15rem;
  height: 15rem;
  object-fit: cover; /* Optional: adjust image fit */
}

.middle {
  transition: .5s ease;
  opacity: 0.8;
  position: absolute;
    top: 95%;
    left: 40%;
    width: 15rem;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.img-container:hover .image {
  opacity: 0.3;
}
img:hover {
  opacity: 0.3;
}

.img-container:hover .middle {
  opacity: 1;
}
</style>
