<template>
<div class="card">
    <div class="main">
        <s-form @submit="addItem">
    <div>
    <div class="row">
        <div class="col-lg-4">
            <profile-image @success="setupImage" :imageURL="data.image" style="margin-top:1rem;"></profile-image>
        </div>
            <div class="col-lg-8">
             <div class="card">
                <div class="row">
                    <div class="col-4">
                    <validated-input  label="First Name" color="light" v-model="data.first_name" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2"  :rules="{required:true}"  placeholder="First name" ></validated-input>
                </div>
                <div class="col-4">
                    <validated-input  label="Last Name" color="light" label-classes="font-weight-600" v-model="data.last_name"  input-classes="bg-1 mt-2" placeholder="Last name"></validated-input>
                </div>
                     <div class="col-4">
                    <validated-input type="tel" class="field-required"  label="Phone Number" color="light" v-model="data.mobile_number" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="Phone"  :rules="rules.phoneNumValidation"></validated-input>
                </div>
                </div>

            <div class="row">
                 <div class="col-4">
                    <validated-input type="mail" class="field-required"  label="Email" color="light" label-classes="font-weight-600" v-model="data.email" input-classes="bg-1 mt-2"  placeholder="Email"  :rules="rules.emailValidation"  ></validated-input>
                </div>
                <div class="col-4">
                    <validated-vue-select :options="genderOption" class="field-required"  label="Gender" color="light" label-classes="font-weight-600" v-model="data.gender" input-classes="bg-1 mt-2" :rules="{required:true}" placeholder=" select gender" ></validated-vue-select>
                </div>
                <div class="col-4">
                    <validated-date-picker  label="Date Of Birth" class="field-required" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="data.dob"  placeholder="date of birth" :rules="{required:true}" format="DD-MM-YYYY" :disabled-date="disableFutureDate" ></validated-date-picker>
                </div>
</div>

            <div class="row">

                 <div class="col-4">
                    <validated-vue-select :options="designationOptions" class="field-required"  label="Designation" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Designation" v-model="data.designation"  :rules="{required:true}" ></validated-vue-select>
                </div>
<!--                      <div class="col-lg-8">
                    <validated-text-area  class="field-required"  label="Address" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Address" v-model="data.address"  :rules="{required:false}" ></validated-text-area>
                </div>-->
<!--                 <div class="col-3">
                    <validated-input type="number" class="field-required"  label="Experience" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="Experience"  :rules="rules.positiveOnly" ></validated-input>
                </div>
                 <div class="col-5">
                    <validated-input  class="field-required"  label="Social Media Links" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="Website/Social Media Links"  :rules="{required:true}" ></validated-input>
                </div>-->

            </div>
             </div>
            </div>
    </div>
        </div>
            <div class="text-right pr-0 mt-3">
    <div class="btn-group">
                            <btn text="Update Details" class="px-4" :loading="loading" loading-text="Saving..."></btn>
                        </div>
                </div>
            </s-form>
        </div>
</div>

</template>

<script>
import axios from 'secure-axios';
import ProfileImage from './profileImage';
import urls from '../../../data/urls';

export default {
    name: 'basicDetailEdit',
    components: { ProfileImage },
    props: {
        data: {

        }
    },
    mounted () {
        console.log('data', this.data[0].fname);
    },
    data () {
        return {
            loading: false,
            URL: urls.auth.userAddEdit,
            designationOptions: [
                { value: 'HR', label: 'HR' },
                { value: 'Team Lead', label: 'Team Lead' },
                { value: 'Frontend Developer', label: 'Frontend Developer' },
                { value: 'Junior Frontend Developer', label: 'Junior Frontend Developer' },
                { value: 'Backend Developer', label: 'Backend Developer' },
                { value: 'Junior Backend Developer', label: 'Junior Backend Developer' },
                { value: 'Project Manager', label: 'Project Manager' },
                { value: 'Tester', label: 'Tester' }
            ],
            genderOption: [
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' }
            ],
            rules: {

                phoneNumValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.isValidMobileNumber(value);
                    }
                },
                emailValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.isValidEmail(value);
                    }
                },

                positiveOnly: {
                    required: true,
                    customValidator: (value) => {
                        return this.avoidNegetive(value);
                    }
                }

            }
        };
    },
    methods: {
        avoidNegetive (endDate) {
            const num = endDate;
            if (num < 0) {
                return 'Please enter a valid Year';
            }
            return true;
        },
        setupImage (item) {
            console.log('setup image ', item);
            this.data.profile_image = item;
        },
        isValidEmail (email) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (emailRegex.test(email)) {
                return true;
            } else {
                return 'Please enter a valid email address';
            }
        },
        isValidMobileNumber (phoneNumber) {
            const minDigits = 10;
            const maxDigits = 13;

            if (phoneNumber.length < minDigits || phoneNumber.length > maxDigits) {
                return 'Enter a valid number (between ' + minDigits + ' and ' + maxDigits + ' digits)';
            }

            if (!/^\d+$/.test(phoneNumber)) {
                return 'Enter a valid number (only digits)';
            }

            return true;
        },
        disableFutureDate (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
        async addItem () {
            try {
                this.loading = true;
                console.log('data', this.data);
                const response = await axios.form(this.URL, this.data);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    console.log('result data after edit', result.data);
                    this.$emit('success', result.data);
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.model.error_response = [''];
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
