<template>
  <div>
      <loading-animation v-if="loading"></loading-animation>
      <div v-else>
    <div class="mb-4">
        <div class="fl-te-c">
      <h2 class="text-primary font-poppins-semibold">{{ items.module_name }}</h2>
            <btn design="basic-a" class="btn float-right" icon="fa fa-edit" text="Edit Details" @click="editItem"></btn>
        </div>
      <p class="">{{ items.description }}</p>
    </div>
          </div>
           <modal title="Edit Module" ref="editmodule" :no-close-on-backdrop="true" width="40r" header-color="primary">
                    <edit-module :data="{...editingItem}" :editDetail="true" :id="projId" :projectId="this.$route.params.project" @successUpdate="formSuccess"></edit-module>
           </modal>
  </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import EditModule from './editModule';

export default {
    name: 'viewModule',
    components: { EditModule },
    props: {
        data: {}
    },
    mounted () {
        console.log('data of project view', this.data);
        this.fetchInfo();
    },
    data () {
        return {
            projId: this.$route.params.project,
            editingItem: '',
            URL: urls.Module.Details,
            items: '',
            loading: false
        };
    },
    methods: {
        editItem () {
            this.editingItem = [];
            this.editingItem = this.items;
            /*   console.log('data for edit module table', data); */
            this.$refs.editmodule.show();
        },
        formSuccess (item) {
            console.log('form sucess updated Data ', item);
            this.items = [];
            this.items = item;
            this.$refs.editmodule.close();
            /*  this.$refs.table.refreshTable(); */
        },
        async fetchInfo () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, { id: this.data });
                const result = response.data;
                this.items = result.data;
                console.log('result', this.items);
                if (this.items) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }

    }
};
</script>

<style scoped>

</style>
