import Modal from 'lego-framework/src/ajax/ConfirmPostModal';
export default {
    name: 'enable-modal',
    extends: Modal,
    props: {
        width: { type: String, default: '30r' },
        title: { type: String, default: 'Reset Password' },
        headerColor: { type: String, default: 'warning' },
        bodyColor: { type: String, default: 'warning' },
        dialog: { type: Boolean, default: true },
        okButton: { type: String, default: 'Reset' },
        okButtonColor: { type: String, default: 'warning' },
        cancelButton: { type: String, default: 'Cancel' },
        cancelButtonColor: { type: String, default: 'primary' }
    }
};
