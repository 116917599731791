<template>
<div  class="home of-h full-height p-5">
        <div class="card w-30r mx-auto mt-6 pt-5 pb-5">
            <s-form @submit="setPassword" key="login" role="form">
            <h4 class="text-primary text-center font-poppins-semibold fs-lg-2 mt-2" >Reset Your Password</h4>
<!--            <div class="mt-5">
                <validated-input icon="fa fa-lock" type="password" placeholder="Old Password" class="ml-4 w-25r field-required" v-model="model.old_password"  :rules="{required:true}"></validated-input>
            </div>-->
            <div class="mt-5">
                <validated-input icon="fa fa-lock" type="password" placeholder="New Password" class="ml-4 w-25r field-required" v-model="model.new_password" :rules="{required:true}"></validated-input>
            </div>
            <div class="mt-5">
                <validated-input icon="fa fa-lock" type="password" placeholder="Confirm Password" class="ml-4 w-25r field-required" v-model="model.confirm_password"  :rules="{required:true}"></validated-input>
            </div>
                 <div class="error-message" v-if="errorMessage" v-html="errorMessage"></div>

            <div class="text-center mt-4">
            <btn text="Submit" :loading="loading" loading-text="Resetting..." class="mt-4 px-4" size="md"></btn>
            </div>
                </s-form>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'InitialPasswordReset',
    data () {
        return {
            errorMessage: '',
            loading: false,
            URL: urls.auth.InitialReset,
            rules: {
                confirmPass: {
                    required: true,
                    customValidator: () => {
                        return this.validationConfirmPassword();
                    }
                }

            },
            model: {
                /*  old_password: '', */
                new_password: '',
                confirm_password: ''
            }
        };
    },
    methods: {
        validationConfirmPassword () {
            const conformPass = this.model.confirm_password;
            if (this.model.new_password !== conformPass) {
                return 'Enter same as new password';
            }
            return true;
        },
        async setPassword () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    // this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/login/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                                console.log('error massage in if ', errorMessage);
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                                console.log('error massage  ', errorMessage);
                            }
                            console.log('error message printing ', errorMessage);
                            this.errorMessage = errorMessage;

                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    /* const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    } */
                    this.loading = false;
                }
            } catch (error) {
                this.errorMessage = 'Something Went Wrong..!!';

                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 0.75rem;
    border-radius: 0.25rem;
    text-align: center;
    margin-top: 1rem;
}
</style>
