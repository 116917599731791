<template>
<div class="main ">

  <div class="modal-footer fl-x fl-rev">
      <btn class=" btn-basic-b btn-color-danger round-1 bs-1 bs-1-h ml-3" type="button" design="basic-b"  text="No" @click="cancelClicked"></btn>
    <btn text="Delete"  id="delete" type="button" class=" btn-basic btn-color-danger round-1 bs-1 bs-1-h"  @click="okClicked"></btn>
  </div>
</div>
</template>

<script>
export default {
    name: 'CustomDelete',
    props: {
        index: {

        }
    },
    data () {
        return {
            status: true
        };
    },
    mounted () {
        console.log('delete Modal', this.index);
    },
    methods: {
        okClicked () {
            this.status = true;
            this.$emit('buttonClickedOK', this.index);
        },
        cancelClicked () {
            this.status = false;
            this.$emit('buttonClickedCancel');
        }
    }

};
</script>

<style scoped>
#delete{
 /* background-color: red;*/
}

</style>
