<template>
   <div class="main" style="margin-left: -0.9rem;">
        <s-form @submit="addItem">

            <div class="row">
                <div class="col-lg-12 m-2">
                    <validated-input  label="Project Name" color="light" label-classes="font-weight-600" :rules="{required:true}" input-classes="bg-1 mt-2" v-model="data.project_name" class="field-required"  placeholder="Project Name"></validated-input>
                </div>

                <div class="col-lg-12 m-2">
                    <validated-text-area :rules="{required:true}" class="field-required"  label="Description" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Description" v-model="data.description"></validated-text-area>
                </div>
                <div class="col-lg-12 m-2" v-if="detailEdit">
                    <validated-ajax-select :url="UserURL" multiple class="c-input-select field-required input-border-primary" label-classes="font-weight-500" input-classes="mt-2" v-model="team_members" placeholder="Team members"
                                               :rules="{required:true}"  label="Team Members"></validated-ajax-select>
                </div>
              <div class="col-lg-12 m-2" v-else>
                    <validated-ajax-select :url="UserURL" multiple class="c-input-select field-required input-border-primary" label-classes="font-weight-500" input-classes="mt-2" v-model="data.team_members" placeholder="Team members"
                                               :rules="{required:true}"  label="Team Members"></validated-ajax-select>
                </div>

            </div>
            <div class="row">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Submit" class="px-4" :loading="loading" loading-text="Saving..."></btn>

                        </div>
                    </div>
                </div>
            </div>
        </s-form>

</div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'editProject',
    props: {
        data: {
            type: Object
        },
        detailEdit: {
            type: Boolean
        }
    },
    data () {
        return {
            updatedItems: [],
            team_members: [],
            loading: false,
            UserURL: urls.projects.UsersVueSelect,
            URL: urls.projects.AddEdit
        };
    },
    mounted () {
        console.log('edit pro', this.data);
        console.log('edit project team members', this.data.team_members);
        if (this.detailEdit) {
            console.log('fetch of team members hits');
            this.fetchIdOfTeamMembers();
        }
    },
    methods: {
        fetchIdOfTeamMembers () {
            /*  this.data.team_members = this.data.team_members_list.map(member => member.id); */
            for (const member of this.data.team_members_list) {
                this.team_members.push(member.id);
            }
            console.log('edit project team members in fetch function', this.team_members);
        },
        async addItem () {
            try {
                this.loading = true;
                if (this.detailEdit) {
                    console.log('if condition in add item ');
                    this.data.team_members = this.team_members;
                }
                const response = await axios.form(this.URL, this.data);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Updated Successfully', 'Success', { type: 'success' });
                    if (this.detailEdit) {
                        this.updatedItems = response.data.data;
                        console.log('Updated Data', this.updatedItems);
                        this.$emit('successUpdate', this.updatedItems);
                    }
                    this.$emit('success');

                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
