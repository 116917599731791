<template>
    <div>
        <loading-animation v-if="loading === true"></loading-animation>
        <div v-else >
        <div class="mb-4">
            <div class="fl-te-c">
            <h2 class="text-primary font-poppins-semibold">{{ items.project_name }}</h2>
                <btn class="btn float-right" type="button" design="basic-a" icon="fa fa-edit" text="Edit Details" @click="editProject"></btn>
            </div>
            <p class="">{{ this.items.description }}</p>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <h5 class="text-primary">TEAM MEMBERS</h5>
            </div>
            <div class="col" v-for="(user,index) in items.team_members_list" :key="index">
                <p class="mb-1"><b>{{ user.name }}</b></p>
                <p class="mb-1">{{ user.designation }}</p>
                <p>{{ user.username }}</p>
            </div>
        </div>
            </div>
          <modal title="Edit Project" ref="editProj" :no-close-on-backdrop="true" width="40r" header-color="primary">
                <edit-project @successUpdate="formSuccess" :detailEdit="true" :data="{...editingItem}"></edit-project>
            </modal>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import EditProject from './editProject';

export default {
    name: 'viewProj',
    components: { EditProject },
    props: {
        projectId: {
            type: String,
            default: ''
        }
    },
    mounted () {
        console.log('projectId of project view', this.projectId);
        this.fetchInfo();
    },
    data () {
        return {
            editingItem: '',
            loading: false,
            URL: urls.projects.Details,
            items: ''
        };
    },
    methods: {
        editProject () {
            this.editingItem = [];
            this.editingItem = this.items;
            console.log('data from project table');
            this.$refs.editProj.show();
        },
        formSuccess (item) {
            console.log('form sucess updated Data ', item);
            this.items = [];
            this.items = item;
            this.$refs.editProj.close();
        },
        async fetchInfo () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, { id: this.projectId });
                const result = response.data;
                this.items = result.data;
                console.log('result', this.items);
                if (this.items) {
                    /* this.$notify('Added Successfully', 'Success', { type: 'success' }); */
                    this.loading = false;
                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }

    }
};
</script>

<style scoped>

</style>
