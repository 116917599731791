<template>
<div class="main card mt-4 mr-4">
    <h2 class="m-3 mb-4">Notification</h2>
    <div v-for="(item,index) in notificationArray" :key="index">
        <div class="row m-3 card pl-3 pr-3" id="notifyBlock">
         <h5 class="mr-auto mr-3 " style="padding-inline: 1rem">{{item.title}}</h5>
         <btn class="btn" design="basic-a" size="m" text="view" type="button" icon="fa fa-eye" style="margin-right: 0.8rem" @click="details(item)"></btn>
        </div>

    </div>
     <modal ref="detail" title="Notification Details" header-color="primary" :no-close-on-backdrop="true" :no-close-on-esc="true" width="35r">
       <notification-detail :data="passingData"></notification-detail>
     </modal>
</div>
</template>

<script>
import NotificationDetail from './notificationDetail';
import axios from 'secure-axios';
import urls from '../../../../data/urls';
export default {
    name: 'notificationView',
    components: { NotificationDetail },
    data () {
        return {
            listURL: urls.Notification.List,
            passingData: '',
            notificationArray: []
        };
    },
    mounted () {
        this.fetchItem();
    },
    methods: {
        details (item) {
            this.passingData = '';
            this.passingData = item;
            this.$refs.detail.show();
        },
        async fetchItem () {
            try {
                this.loading = true;
                const response = await axios.get(this.listURL);
                const result = response.data.data;
                this.notificationArray = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
#notifyBlock{
    background: #e3f7fc;
}
</style>
