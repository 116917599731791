<template>
    <div class="card">
        <s-form @submit="addItem" ref="form" class="">

            <div class="row">
                <div class="col-lg-12 ">
                    <validated-input label="Module Name" color="light" class="field-required" label-classes="font-weight-600" input-classes="bg-1 mt-2" v-model="model.module_name"
                                     :rules="{required:true}" placeholder="Module Name"></validated-input>
                </div>

                <div class="col-lg-12 ">
                    <validated-text-area label="Description" class="field-required" label-classes="font-weight-600" input-classes="mt-2" placeholder="Description"
                                         :rules="{required:true}" v-model="model.description"></validated-text-area>
                </div>

            </div>
         <div class="col-12 text-right">
                            <btn text="Save" :loading="loading" loading-text="saving..." class="px-4"></btn>

             </div>
        </s-form>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'addModule',
    data () {
        return {
            URL: urls.Module.AddEdit,
            loading: false,
            model: {
                module_name: '',
                description: '',
                project: this.id
            }
        };
    },
    props: {
        id: {

        }
    },

    mounted () {
        console.log('id', this.id);
    },
    methods: {

        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$emit('success');
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        revert () {
            this.$router.push({ path: '/' + this.model.project + '/modules/' });
        }
    }
};
</script>

<style scoped>

</style>
