<template>
<div>

     <div class="main">
        <div class="card p-3" style="margin:-15px -1rem">
            <div class="list ">
<!--                  <div class="fl-x fl-j-e">
            <btn class="btn float-right" type="button" design="basic-b" icon="fa fa-plus" text="ADD" @click="addData"></btn>
        </div>-->

                <simple-table  :fields="fieldsName" ref="pramTable" :data="tableddata"
                           :show-search-box="false" :show-pagination="false" class="paramTable">

<!--                    <template slot="actions" slot-scope="props" ref="table">
                         <div class="btn-group">
                            <btn size="sm" icon="fa fa-pencil" type="button" @click="editItem(props.rowData)"></btn>
                            <btn size="sm" icon="fa fa-trash" type="button" color="danger" @click="deleteItem(props.rowData)"></btn>

                             </div>
                    </template>-->
                </simple-table>

            </div>

        </div>

    </div>

</div>
</template>

<script>
/* import ProjectEdit from './projectEdit';
import ProjectAdd from './projectAdd';
import DeleteModal from '../../commonComponents/deleteModel'; */
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'projectList',
    /*  components: { DeleteModal, ProjectAdd, ProjectEdit }, */
    data () {
        return {
            URL: urls.projects.List,
            userId: this.$route.params.id,
            editingInfo: '',
            fieldsName: [
                {
                    name: 'project_name',
                    title: 'Project Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                }

            ],
            tableddata: [
            ]
        };
    },
    mounted () {
        this.fetchInfo();
    },
    methods: {
        async fetchInfo () {
            try {
                const response = await axios.get(this.URL + '?user_id=' + this.userId);
                this.loading = true;

                const result = response.data;
                this.tableddata = result.data;
                console.log('result', this.dataItems);
                if (this.dataItems) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
        /*        SuccessEdit () {
            this.$refs.edit.close();
        },
        editItem (item) {
            this.editingInfo = item;
            this.$refs.edit.show();
        },
        deleteItem (item) {
            this.itemId = item;
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.deleteModal.close();
        },
        addData () {
            this.$refs.add.show();
        },
        SuccessForm () {
            this.$refs.add.close();
        } */
    }
};
</script>

<style scoped>

</style>
