<template>
    <div>
        <div class="main">
            <div>
                                 <basic-details-view :data="items"></basic-details-view>
            </div>
            <details-tabs class="mt-n3">
            <tab title="Documents" class="">
                 <document-list></document-list>
            </tab>
              <tab title="Project" class="">
                 <project-list></project-list>
            </tab>
            </details-tabs>

        </div>
    </div>
</template>

<script>
import BasicDetailsView from './basicDetailsView';
import DetailsTabs from '../details-page/details-tabs';
import ProjectList from './projectList';
import DocumentList from './documentList';
export default {
    name: 'detailsView',
    components: { DocumentList, ProjectList, DetailsTabs, BasicDetailsView },
    data () {
        return {
            items: [
                {
                    fname: 'Abijith',
                    lname: 'sasi',
                    phone: '767786768',
                    email: 'abi@gmail.com',
                    gender: 'male',
                    dob: '09-03-2023',
                    experiance: 4,
                    designation: 'HR',
                    address: 'jh@gmail.com',
                    sociallink: 'www.jk.com',
                    masters: {
                        course: 'MCA',
                        institution: 'ABC Collage'
                    },
                    bachelors: {
                        course: 'BCA',
                        institution: 'ABC Collage'
                    },
                    plustwo: {
                        course: 'Computer Science',
                        institution: 'bgh School'
                    },
                    primaryEducation: {
                        course: '10th',
                        institution: 'ABC school'
                    }
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
