<template>
    <div class="main mt-4">
        <loading-animation v-if="loading"></loading-animation>
            <div v-else>
        <div class="card">
            <div class="card-header mb-5 ">
                <div class="fl-te-c">
                      <h2 class="text-primary font-poppins-semibold">{{ items.api_name }}</h2>
                     <btn design="basic-a" class="btn float-right" icon="fa fa-edit" text="Edit Details" @click="editItem"></btn>
                </div>
            </div>
            <div class=" mt-5">
                <div class="row mt-4">
                    <h5 class="col-lg-12 text-primary">URL : </h5>
                    <p class="col-lg-12">{{ items.api_url }}</p>
                </div>
                <div class="row mt-3">
                    <h5 class="col-lg-12 text-primary">Method : </h5>
                    <p class="col-lg-12">{{ items.api_method }}</p>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-12">

                        <h5 class="text-primary">Parameter : </h5>

                    </div>
                    <div class="col-lg-12">
                        <simple-table :fields="fieldsName" ref="paramsTable" :data="items.parameters_array"
                                      :show-search-box="false" :show-pagination="false">
                        </simple-table>
                    </div>

                </div>

                <div class="row mt-3">
                    <h5 class="col-lg-12 text-primary">Success Response : </h5>
                    <pre class="col-lg-12">
                            <code>
                                {{ items.success_response }}
                                </code>
                    </pre>

                </div>

                <div class="row mt-3">
                    <h5 class="col-lg-12 text-primary">Error Response : </h5>
                    <div class="col-lg-9 mt-2">
                    <div v-for="(errorRes, index) in items.error_response_array" :key="index" class="d-flex">

                        <span >Response {{ index+1 }} </span> <span class="ml-1">:</span>
                    <pre>
                        <code>
                            {{ errorRes.response }}
                        </code>
                    </pre>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--        <modal title="Edit API" ref="editAPI" :no-close-on-backdrop="true" width="60r" header-color="primary">
           <edit-api></edit-api>
        </modal>-->
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
export default {
    name: 'viewApi',

    mounted () {
        this.fetchInfo();
    },
    data () {
        return {

            length: '',
            /*  projectId: this.$route.params.project,
            id: this.$route.params.id,
            moduleInfo: this.$route.params.module, */
            fieldsName: [
                {
                    name: 'field_name',
                    title: 'Field Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'field_type',
                    title: 'Data Type',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'required',
                    title: 'Required',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'max_length',
                    title: 'Max Length',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: 'min_length',
                    title: 'Min Length',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: 'default_value',
                    title: 'Default Value',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                }
            ],
            items: '',
            ApiInfo: '',
            URL: urls.ApiSection.Details,
            APIID: this.$route.params.id,
            loading: true
        };
    },
    methods: {
        editItem () {
            console.log('id', this.projectId);
            this.$router.push({ path: '/api-detail-edit/' + this.items.module + '/' + this.items.id + '/' });
        },
        /*  successEdit () {
            this.$refs.editAPI.close();
        }, */
        async fetchInfo () {
            try {
                const response = await axios.form(this.URL, { id: this.APIID });
                this.loading = true;

                const result = response.data;
                this.items = result.data;
                console.log('result', result);
                console.log('items', this.items.parameters_array);
                this.stringConversion();
                this.length = this.items.parameters_array.length;
                if (this.items) {
                    this.loading = false;

                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        stringConversion () {
            this.tableData = JSON.parse(this.items.parameters);
            /* const arrayOfObjects = Object.entries(this.tableData).map(([key, value]) => ({ [key]: value })); */
            console.log('dataTYpe', typeof this.tableData);
            console.log('obj', this.tableData);
            /* console.log('after convert to array from obj', arrayOfObjects); */
            /* this.tableData = arrayOfObjects; */
        }
    }

};
</script>

<style scoped>

</style>
