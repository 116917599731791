<template>
    <div class="pt-4">
<div class="main m-2 card">
        <s-form @submit="addItem" ref="form" class="p-3">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-primary fs-lg-4 font-poppins-semibold" style=" font-weight: 650;">New API</h3>
                </div>
                </div>
            <div class="row mt-4">
                <div class="col-lg-12 m-2">
                    <validated-input  label="API Name" color="light" class="field-required" :rules="{required:true}" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="Name" v-model="model.api_name"></validated-input>
                </div>
                <div class="col-lg-12 m-2">
                    <validated-input  label="URL" color="light" class="field-required" :rules="{required:true}"  label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="Enter URL" v-model="model.api_url"></validated-input>
                </div>
                <div class="col-lg-12 m-2">
                    <validated-vue-select :options="methodOption"  class="field-required" :rules="{required:true}" label="Method" color="light" label-classes="font-weight-600" input-classes="bg-1 mt-2"  placeholder="methods" v-model="model.api_method"></validated-vue-select>
                </div>
                <div class="col-lg-12 m-2">
<!--                     <label class="font-weight-700">Parameter</label>-->
                    <parameter-list :params="pramlist" @successList="catchData"></parameter-list>
                </div>

                <div class="col-lg-12 m-2">
                    <validated-text-area  label="Success Response" class="field-required" :rules="{required:true}" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Response" v-model="model.success_response"></validated-text-area>
                </div>
               </div>

                  <div class="row" v-for="(errorRes, index) in model.error_response" :key="index">
                     <div class="col-12 m-2">
                    <validated-text-area  label="Error Response" class="field-required" :rules="{required:true}" label-classes="font-weight-600"  input-classes="mt-2" placeholder="Response" v-model="errorRes.response"></validated-text-area>
                </div>
                <div class="col-lg-2 d-flex">
                    <btn text="Add Response" v-if="index === model.error_response.length - 1"
                         type="button" icon="fa fa-plus"
                         @click="addResponse(index, errorRes.response)"
                         class="mt-lg-4 ml-2"></btn>
                    <div v-if="resDelete">
                    <btn text="Remove Response" v-if="index === model.error_response.length - 1"
                         type="button" icon="fa fa-minus"
                         @click="removeResponse"
                         class="mt-lg-4 ml-2"></btn>
                        </div>
                </div>

            </div>
            <div class="row mt-lg-6">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Add API" class="px-4" :loading="loading" loading-text="Saving..."></btn>
                                                        <btn text="Cancel" type="reset" @click="revert" design="basic-b" class="px-4"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </s-form>

</div>
        </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import ParameterList from './parameterList';

export default {
    name: 'AddApi',
    components: { ParameterList },
    props: {
        data: {
            type: Object
        }
    },

    data () {
        return {
            projectId: this.$route.params.project,
            resDelete: false,
            pramlist: '',
            URL: urls.ApiSection.AddEdit,
            loading: false,
            model: {
                api_name: '',
                api_url: '',
                api_method: '',
                success_response: '',
                /*  response: '', */
                parameters: {},
                error_response: [
                    {
                        response: ''
                    }
                ],
                module: this.$route.params.id
            },
            methodOption: [
                { value: 'POST', label: 'POST' },
                { value: 'GET', label: 'GET' },
                { value: 'PATCH', label: 'PATCH' },
                { value: 'DELETE', label: 'DELETE' },
                { value: 'PUT', label: 'PUT' },
                { value: 'HEAD', label: 'HEAD' },
                { value: 'OPTIONS', label: 'OPTIONS' }

            ]

        };
    },
    methods: {
        responseLengthCheck () {
            console.log('in lenth checking', this.model.error_response.length);
            if (this.model.error_response.length > 1) {
                this.resDelete = true;
            } else {
                this.resDelete = false;
            }
        },
        removeResponse () {
            if (this.resDelete) {
                this.model.error_response.pop();
                this.responseLengthCheck();
            } else {
                console.log('else hitted');
                this.model.error_response = '';
            }
        },

        catchData (item) {
            console.log('item in add api for arry', item);
            this.pramlist = item;
        },
        isJson (str) {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        },

        addResponse (index, res) {
            console.log('err arry', this.model.error_response);
            console.log('err arry length', this.model.error_response.length);
            console.log('err arry type', typeof this.model.error_response);
            this.model.error_response.push({ response: '' });
            this.model.error_response[index].response = res;
            this.responseLengthCheck();
        },
        convert () {
            console.log('in submit button', this.pramlist);
            console.log('dataTYpe', typeof this.pramlist);
            const myJSON = JSON.stringify(this.pramlist);
            const myJSON2 = JSON.stringify(this.model.error_response);
            this.isJson(myJSON);
            this.isJson(myJSON2);
            console.log('dataTYpe o concerted data', this.isJson(myJSON));
            console.log('dataTYpe of response', this.isJson(myJSON2));
            console.log('dataTYpe', this.isJson(myJSON));
            console.log('dataTYpe rwesponse', this.isJson(myJSON2));

            this.model.parameters = myJSON;
            this.model.error_response = myJSON2;
            console.log('after conversion', this.model);
        },

        async addItem () {
            try {
                this.loading = true;
                this.convert();
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/apis/' + this.$route.params.id + '/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.model.error_response = [''];
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Failed',
                    'There was some communication issues while communicating with the server, Please try again later.',
                    { type: 'danger' }
                );
                this.model.error_response = [''];
                this.loading = false;
            }
        },

        revert () {
            this.$router.push({ path: '/apis/' + this.$route.params.id + '/' });
        }
    }

};
</script>

<style scoped>

</style>
