<template>
    <div class="main">
        <div class="card">
            <div class="list ">
                  <div class="fl-te-c">
            <h5 class="text-center font-poppins-semibold" style="margin-left: -0.9rem;">Parameter</h5>
            <btn class="btn float-right" type="button" design="basic-b" icon="fa fa-plus" text="ADD" @click="addData"></btn>
        </div>

                <loading-animation v-if="editLoading"></loading-animation>
                <simple-table v-else :fields="fieldsName" ref="pramTable" :data="tableddata"
                           :show-search-box="false" :show-pagination="false" class="paramTable">

                    <template slot="actions" slot-scope="props" ref="table">

                        <div class="btn-group ml-3">
                            <btn size="sm" icon="fa fa-pencil" type="button" @click="editItem(props.rowData)"></btn>

                        </div>

                        <div class="btn-group  ml-3">
                            <btn size="sm" icon="fa fa-trash" type="button" color="danger" @click="deleteItem(props.rowData)"></btn>

                        </div>
                    </template>
                </simple-table>

                <modal title="Add Parameter" ref="addparams" :no-close-on-backdrop="true" width="60r" header-color="primary">
                    <parameter-add :data="tableddata" @success="SuccessForm"></parameter-add>
                </modal>
                <modal title="Edit Parameter" ref="editparams" :no-close-on-backdrop="true" width="60r" header-color="primary">
                    <parameter-edit :data="editingInfo"  :arrayData="tableddata" :index="indexArray" @editStateChange="changeStateEdit" @success="SuccessEdit"></parameter-edit>
                </modal>
                <modal title="Are you sure ?" footer-color="light" ref="delete" :no-close-on-backdrop="false" width="40r" header-color="danger">
                    <CustomDelete :index="indexArray" @buttonClickedCancel="CancelClicked" @buttonClickedOK="okClicked"></CustomDelete>
                </modal>
            </div>

        </div>

    </div>
</template>
<script>
import ParameterAdd from './parameterAdd';
import ParameterEdit from './parameterEdit';
import CustomDelete from './customDelete';

export default {
    name: 'parameterList',
    components: { CustomDelete, ParameterEdit, ParameterAdd },
    props: {
        data: {

        },
        params: {

        }

    },

    data () {
        return {
            editLoading: false,
            indexArray: '',
            dataEditing: '',
            editingInfo: '',
            dataLoading: false,
            fieldsName: [
                {
                    name: 'field_name',
                    title: 'Field Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'field_type',
                    title: 'Data Type',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'required',
                    title: 'Required',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'max_length',
                    title: 'Max Length',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: 'min_length',
                    title: 'Min Length',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: 'default_value',
                    title: 'Default Value',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: '__slot:actions',
                    title: 'Action',
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                }
            ],
            tableddata: []
        };
    },

    mounted () {
        console.log('IN param list', this.tableddata);
        console.log('IN param list param data', this.data);
    },
    methods: {
        changeStateEdit () {
            this.editLoading = true;
        },

        deleteObjectAtIndex (array, index) {
            if (index >= 0 && index <= array.length) {
                array.splice(index, 1);
            }
        },
        CancelClicked () {
            this.$refs.delete.close();
            this.$emit('successList', this.params);
        },
        okClicked (item) {
            console.log('index in ok clicked', this.indexArray);
            this.deleteObjectAtIndex(this.tableddata, item);
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.delete.close();
            this.params = this.tableddata;
            console.log('params in prm_list', this.params);
            this.$emit('successList', this.params);

            /* this.$refs.pramTable.refreshTable();
            this.$refs.pramTable.refreshTable(); */
            console.log('after refresh', this.tableddata);
        },
        editItem (item) {
            /* this.editingInfo = []; */
            /* this.editLoading = true; */
            console.log('Editing item', item);
            this.dataEditing = item;
            this.editingInfo = { ...item };
            this.indexArray = this.tableddata.indexOf(this.dataEditing);
            this.$refs.editparams.show();
        },

        addData () {
            this.$refs.addparams.show();
        },
        SuccessForm (item) {
            console.log('In Param List Successform', item);
            this.tableddata = item;
            this.$refs.addparams.close();
            this.params = this.tableddata;
            this.$notify('Parameter Added Successfully', 'Success', { type: 'success' });

            console.log('params in prm_list', this.params);
            this.$emit('successList', this.params);

            /* this.$refs.pramTable.refreshTable(); */
        },
        SuccessEdit (data) {
            this.$refs.editparams.close();
            this.tableddata = [];

            this.tableddata = data;

            this.params = this.tableddata;
            setTimeout(() => {
                this.$notify('Parameter Updated Successfully', 'Success', { type: 'success' });
                this.editLoading = false;
            }, 30);

            console.log('params in prm_list', this.params);
            console.log('IN Successs Edit - after edit array', this.tableddata);
            this.$emit('successList', this.params);

            /* this.$refs.pramTable.refreshTable(); */
        },
        replaceObjectAtIndex (array, index, newObject) {
            try {
                // Check if the index is within the array bounds
                if (index >= 0 && index < array.length) {
                    array[index] = newObject;
                    return array; // Return the modified array
                } else {
                    throw new Error('Invalid index');
                }
            } catch (error) {
                console.error('Error:', error.message);
                return array; // Return the original array if an error occurs
            }
        },
        deleteItem (data) {
            this.$refs.delete.show();
            this.indexArray = this.tableddata.indexOf(data);
            /* if (status === true) {
                this.tableddata.pop()
            } */
        }

    }
};
</script>

<style scoped>
.paramTable{
    margin-left: -1.8rem;
}
</style>
